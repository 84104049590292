// vars
$font-family: 'Figtree', sans-serif;
$images-path: '../../img/';

// Colors
// Foris Color Palette
$color-primary: #142d6d;
$color-primary-light: #2748a8;
$color-secondary: #00cc8f;
$color-secondary-hover: #0bbe89;
$color-secondary-semi-transparent: rgba(0, 204, 143, .5);
$color-secondary-semi-transparent-secondary: rgba(0, 204, 143, .1);
$color-secondary-label: #36a084;
$color-secondary-light: #f0f7f5;
$color-secondary-shadow: rgba(0, 218, 153, .1);
$font-color: #0a1e33;
$font-color-grey: #8a9199;
$button-color-primary: #f5bf42;
$button-color-primary-hover: #dbab3b;
$ui-line-divider-grey: #c2ccc9;

// Other colors
$red-primary: #d60019;
$red-secondary: #c00;
$red-label: #e1172a;
$red: #f00;
$red-dark: #900;
$red-transparent: rgba(255, 0, 0, .05);
$shadow-red: rgba(225, 21, 46, .1);
$light-pink: #feadad;

$black: #000;
$near-black: #222;
$shadow-black-1: rgba(0, 0, 0, .25);
$shadow-black-2: rgba(0, 0, 0, .3);
$shadow-black-3: rgba(0, 0, 0, .6);

$white: #fff;
$white-transparent: rgba(255, 255, 255, 0);
$white-semitransparent-1: rgba(255, 255, 255, .8);
$white-semitransparent-2: rgba(255, 255, 255, .9);

$gray-darkest: #242424;
$gray-dark: #444;
$gray-mid-dark: #555;
$gray-mid: #666;
$gray-mid-light: #808080;
$gray-light: #aaa;
$gray-lighter: #ebebeb;
$gray-lightest: #fbfbfb;
$gray-semitransparent: rgba(96, 96, 96, .5);

$gray-green: #c3ccc9;
$gray-blue: #cdd7db;
$gray-light-blue: #b8c8cd;
$gray-darkblue: #3d515c;
$gray-green: #eef9e9;
$gray-dce4e6: #dce4e6; //blue-ish
$input-submit: #c0c0c0;
$input-readonly: #e9ecef;

$orange: #ea8b0e;
$orange-dark: #f60;
$shadow-orange: rgba(255, 165, 0, .1);
$white-orange: #fff7ec;

$yellow: #ffd400;

$green: #090;
$green-shadow: rgba(0, 153, 0, .3);

$blue-gray: #557792;
$blue-light: #cceffc;
$blue-bol: #103e60;
$darkblue-paypal: #204084;
$darkblue: #1c5e79;
$blue-semitransparent: rgba(0, 174, 241, .5);
$shadow-blue: rgba(0, 174, 239, .1);

$yellow-pale: #f7ecb5;
$white-yellow: #fdf4cc;
