@import 'variables';

.dummy {
    color: $white;
}

// Bootstrap
@include media-breakpoint-up(xl) {
    html,
    body {
        min-width: 1200px !important;
    }
}

.form-control {
    border-color: $ui-line-divider-grey;
    font-size: 14px;
    font-weight: 300;
}

.collapse {
    @include media-breakpoint-up(md) {
        &.show-md {
            display: block;
        }
    }
}

.col-5th {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        .col-#{$breakpoint}-5th {
            flex: 0 0 20%;
            max-width: 20%;
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
            width: 100%;
        }
    }
}

// Reset
a {
    color: $font-color;
    text-decoration: underline;

    .smalllink {
        color: $color-secondary;
        font-size: 11px;
        line-height: 18px;
        text-decoration: none;
    }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tbody,
tfoot,
thead {
    border: 0;
    margin: 0;
    outline: none;
    padding: 0;
}

:focus {
    outline: 0;
}

body {
    background-color: $white;
    font-size: 14px;
    line-height: 1;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

caption,
th,
td {
    font-weight: 300;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
}

* {
    box-sizing: border-box;
}

.fancybox-content {
    padding: 15px;

    @include media-breakpoint-up(lg) {
        padding: 40px;
    }
}

.sliding-menu .sliding-menu__nav::before {
    content: url('/img/icons/breadcrumb-divider-dark.svg');
}

.sliding-menu .sliding-menu__back::before {
    content: url('/img/icons/breadcrumb-divider-dark.svg');
    margin-left: 0;
    transform: rotate(180deg);
}

// Main
html {
    overflow-y: scroll;
}

body,
html {
    background-color: $white;
    color: $font-color;
    font-family: $font-family;
    font-weight: 300;
    margin: 0;
    min-height: 100%;
    padding: 0;
    width: 100%;
}

p {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

td,
th {
    color: $font-color;
    font-size: 14px;
    line-height: 20px;
}

a:hover {
    color: $color-primary-light;
    text-decoration: underline;
}

.container-fluid.container-xl.main > p > a {
    color: $color-primary;

    &:hover {
        color: $color-primary-light;
    }
}

ul,
ol {
    font-size: 14px;
    line-height: 22px;
    margin-left: 25px;
    padding-left: 0;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
    appearance: none;
    border-radius: 3px;
    box-sizing: border-box;
}

input,
textarea,
button {
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 10px;
}

input[type="submit"] {
    background-color: $input-submit;
}

input:read-only {
    background-color: $input-readonly;
}

input.bgloader {
    background: url('/img/loader_20.gif') no-repeat right 3px center !important;
}

button {
    box-sizing: border-box;
    cursor: pointer;
}

sup,
sub {
    position: relative;
    top: -.4em;
    vertical-align: baseline;
}

sub {
    top: .4em;
}

.dnone {
    display: none;
}

.dnone_i {
    display: none !important;
}

.mr0 {
    margin-right: 0 !important;
}

select::-ms-expand {
    display: none;
}

select {
    appearance: none;
    background: $white url('#{$images-path}icons/arrow-down.svg') no-repeat right 10px center;
    background-size: 14px;
    border: 1px solid $ui-line-divider-grey;
    border-radius: 3px;
    box-sizing: border-box;
    color: $gray-dark;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 10px 34px 10px 10px;
    position: relative;
}

input:focus,
select:focus,
textarea:focus {
    border-color: $gray-light !important;
    transition: border .2s;

    @include media-breakpoint-down(md) {
        box-shadow: none !important;
    }
}

select.sort_products {
    background-position: right 10px center;
    border: 1px solid $gray-lighter;
    font-size: 13px;
    padding: 5px 30px 5px 10px;
}

input.error,
textarea.error {
    background-color: $white-orange;
    border: 1px solid $orange;
}

select.error {
    background-color: $white-orange;
    border: 1px solid $orange;
}

input.load_all {
    background-color: $red !important;
    display: block;
    height: 40px;
    padding: 30px;
}

.checkbox {
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: 13px;
}

#gegevens label {
    display: inline-block;
    float: none;
    width: auto;

    &.error {
        display: block;
        margin-top: 0;
    }
}

#gegevens label.pm_label {
    background: no-repeat center left;
    background-size: 40px 595px;
    display: block;
    line-height: 35px;
    margin-left: 28px;
    margin-right: 6px;

    .pay-logo {
        background-size: cover;
        height: 35px;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
    }
}

.pm_box_visible {
    border: 1px solid $ui-line-divider-grey;
    border-radius: 3px;
    margin-bottom: 5px;
    padding: 5px;
}

.pm_box_selected {
    background-color: $color-secondary-light;
    border: 1px solid $ui-line-divider-grey;
}

div.email_suggest {
    font-size: 12px;
    font-style: italic;
    margin: -10px 0 10px;
}

input[type=checkbox].checkbox {
    left: -100000px;
    position: absolute;
}

label.checkbox_label {
    box-sizing: border-box;
    padding: 0 0 0 20px;
    position: relative;
}

label.checkbox_label::before {
    border: 1px solid $font-color-grey;
    border-radius: 3px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 13px;
    left: 0;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    width: 13px;
}

input:checked + label.checkbox_label::before {
    background-color: $color-secondary;
    border: 1px solid $color-secondary;
    color: $white;
    content: '\2713';
    font-size: 12px;
    line-height: 11px;
    padding-left: 2px;
    text-align: center;
    transition: background-color .2s;
}

@-moz-document url-prefix() {
    input:checked + label.checkbox_label::before,
    label.checkbox_label::before {
        line-height: 10px;
        margin-top: -6px;
    }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
}

input.hide_radio {
    left: -10000px;
    position: absolute;
}

label.radio_label {
    box-sizing: border-box;
    padding: 0 0 0 18px;
    position: relative;
}

.form-check-label.radio_label {
    margin: -20px 0 0;
}

label.radio_label::before {
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: 7px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 14px;
    left: 0;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    width: 14px;
}

input:checked + label.radio_label::before {
    border: 5px solid $color-secondary;
    color: $white;
    transition: background-color .2s;
}

.radio-info input[type=radio]:checked + label::before {
    border-color: $gray-lighter;
}

@-moz-document url-prefix() {
    label.radio_label::before {
        margin-top: -6px;
    }
}

#bezorg_cart {
    display: none;
}

#bezorg_cart p,
#bezorg_cart li,
#bezorg_cart td {
    font-size: 11px;
}

#bezorg_cart #hide_from_cart {
    display: none;
}

.attention h1 {
    display: none;
}

form {
    margin: 0;
}

h1,
h1 a,
.merken_header h6 {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
    text-decoration: none;
}

h1 a:hover {
    color: $color-primary-light;
}

h2 {
    color: $font-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
}

h3 {
    font-size: 14px;
}

h4 {
    color: $font-color;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin-top: 20px;
}

h5 {
    background-color: $gray-lighter;
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;
    margin: 40px 0 20px;
    padding: 0 20px;
    text-transform: uppercase;
}

h6 {
    clear: both;
    color: $font-color;
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 5px;
    padding: 0 0 5px;
}

.highlight_box h6 {
    color: $white;
    font-size: 24px;
    font-style: italic;
    line-height: 26px;
    padding: 0 20px;
}

p.title {
    color: $font-color;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

p.subtitle {
    color: $font-color;
    font-size: 12px;
    text-transform: uppercase;
}

ol.black li,
ul.black li {
    color: $font-color;
}

ul.black {
    list-style-type: lower-alpha;
}

.clear {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    line-height: 0;
    overflow: hidden;
}

.clearfix {
    zoom: 1;
}

.clearfix::before,
.clearfix::after {
    content: '';
    display: table;
}

.clearfix::after {
    clear: both;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.error_no_products {
    background-color: $white-orange;
    border: 1px solid $orange;
    padding: 15px;
}

table.data_table {
    border: 1px solid $gray-lighter;
}

table.data_table tr th {
    background-color: $gray-lighter;
    font-weight: bold;
    padding: 3px 5px;
    text-align: left;
}

table.data_table tr td {
    border-top: 1px solid $gray-lighter;
    line-height: 24px;
    padding: 3px 5px;
}

@media screen and (max-width: 600px) {
    table.data_table {
        border: 0;

        a {
            color: $color-primary;
        }

        thead {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border: 1px solid $gray-lighter;
            border-radius: 5px;
            display: block;
            margin-bottom: .625em;
            overflow: hidden;
        }

        td {
            border: 0;
            border-top: 0 !important;
            display: block;
            min-height: 32px;
            text-align: right;
        }

        td::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
        }

        td:first-child {
            background-color: $gray-lighter;
        }
    }
}

table.layout_table {
    border-bottom: 1px solid $gray-lighter;
    border-left: 1px solid $gray-lighter;
}

table.layout_table tr:first-child td,
table.layout_table tr th {
    background-color: $gray-lighter;
    font-weight: bold;
    padding: 3px 5px;
    text-align: left;
}

table.layout_table tr td {
    border-right: 1px solid $gray-lighter;
    border-top: 1px solid $gray-lighter;
    padding: 3px 5px;
}

.fancybox-skin {
    background-color: $white;
    border-radius: 0;
}

.fancybox-nav {
    height: 90%;
}

.fancybox-lock {
    overflow: visible !important;
}

.orange {
    color: $orange;
}

.gototop {
    background: url('#{$images-path}icons/arrows-up-down-white.svg') $color-primary no-repeat center bottom 15px;
    background-size: 20px;
    border: 1px solid $white;
    bottom: 20px;
    box-sizing: border-box;
    display: none;
    height: 40px;
    position: fixed;
    right: 15px;
    width: 40px;
    z-index: 1;
}

.verberg {
    position: absolute;
    top: -10000px;
}

.product_aantal {
    border-radius: 4px;
    margin: 0 5px 0 0;
    opacity: 1;
    width: 70px;
}

.product_aantal {
    -moz-appearance: textfield;
}

.product_aantal::-webkit-outer-spin-button,
.product_aantal::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

* html {
    overflow-x: hidden;
}

.main {
    margin: 0 auto;
    position: relative;
}

.main_container.sinterklaas {
    background-attachment: scroll, fixed;
    background-image: url('/img/feestdagen/footer_sinterklaas.svg'), url('/img/feestdagen/header.svg');
    background-position: center bottom -1px, center top 80px;
    background-repeat: no-repeat, no-repeat;
    background-size: 2898px, 1740px;
    padding-bottom: 60px;
}

.main_container.kerst {
    background-attachment: scroll, fixed;
    background-image: url('/img/feestdagen/footer_kerst.svg'), url('/img/feestdagen/header_kerst.svg');
    background-position: center bottom -125px, center top 80px;
    background-repeat: no-repeat, no-repeat;
    background-size: 2898px, 1740px;
    padding-bottom: 60px;
}


#fullpagebox {
    box-sizing: border-box;
}

.sticky_box {
    position: relative;
    width: 100%;
    z-index: 4;
}

.shadow {
    box-shadow: none !important;

    @include media-breakpoint-down(sm) {
        box-shadow: 0 0 5px 0 $shadow-black-1 !important;
    }
}

.sticky_header {
    position: sticky;
    top: 0;
    z-index: 5;
}

.logoblue {
    color: $color-primary;

    &:hover {
        color: $color-primary-light !important;
    }
}

.green {
    color: $green;
}

.cbody {
    @include media-breakpoint-up(lg) {
        &.col-lg-9 {
            max-width: calc(75% - 1px);
        }
    }
}

.cbody p,
#fullpagebox p,
#fullpagebox table,
.cbody table,
.txt_inline_frame p,
.fancybox-content p {
    margin: 0 0 20px;
}

.cbody ul,
.cbody ol,
#fullpagebox ul,
#fullpagebox ol,
.fancybox-content ul {
    margin-bottom: 5px;
}

.cbody ul ul,
.cbody ul ol,
.cbody ol ol,
.cbody ol ul,
#vk_ov td p,
table.no_margin td p {
    margin-bottom: 0;
}

.cbody td p,
#fullpagebox td p {
    margin: 0;
}

p.no_margin {
    margin: 0;
}

.cbody p a:hover {
    color: $color-primary;
}

h3.pdp_tab,
a.pdp_tab {
    background: none;
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-transform: none;
}

h3.pdp_tab {
    color: $font-color;
    padding-bottom: 5px;
}

a:hover.pdp_tab {
    color: $color-primary-light;
}

.swiper-slide.banner-slide {
    height: calc(100% / 3 - 10px) !important;

    @include media-breakpoint-up(lg) {
        border-radius: 4px;
    }

    &:hover {
        text-decoration: underline;
    }
}

span.del_recent {
    background: url('/img/icons/cross.svg?cache=20180529') no-repeat;
    background-size: 14px;
    cursor: pointer;
    display: block;
    float: right;
    height: 14px;
    margin: 5px 0;
    width: 14px;
}

div.header {
    width: 100%;
}

@include media-breakpoint-up(lg) {
    div.header .logo_shops {
        min-width: 210px;
    }
}

div.header .logo_shops .logo .muts {
    height: 25px;
    position: absolute;
    right: -9px;
    top: -13px;
    z-index: 1;
}

.muts.site6,
.muts.site8 {
    right: -6px !important;
}

div.header .logo_shops .logo {
    display: inline-block;
    position: relative;
}

div.header .logo_shops .logo img {
    display: block;
}

.header-logo-title {
    max-width: 280px;

    @include media-breakpoint-down(md) {
        margin-top: 15px !important;
    }

    @media only screen and (max-width: 450px) {
        margin-top: 0 !important;
    }
}

.header-logo-foris {
    display: inline-flex;
}

.header-logo-foris-image {
    height: auto;
    position: relative;

    @media only screen and (min-width: 450px) {
        height: 50px !important;
    }

    @include media-breakpoint-down(sm) {
        margin: 0 auto !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

.foris-circle {
    display: none !important;

    @include media-breakpoint-up(sm) {
        display: block !important;
        top: 10px;
    }

    @include media-breakpoint-up(md) {
        top: 0;
    }
}

div.header {
    .searchform {
        margin: 0 auto;
        position: relative;
        width: 100%;

        input {
            border: 1px solid $color-primary;
            border-radius: 118px;
            box-sizing: border-box;
            font-size: 14px;
            height: 40px;
            margin: 0;
            padding: 10px 40px 10px 20px;
            position: relative;
            width: 100%;
            z-index: 1;

            &:focus {
                border-color: $color-secondary;
                box-shadow: 0 0 5px $color-secondary-semi-transparent;
            }

            &::placeholder,
            &:-ms-input-placeholder,
            &::-ms-input-placeholder {
                color: $gray-lighter;
                opacity: 1;
            }
        }
    }

    .header_flags {
        display: table-cell;
        vertical-align: middle;
        width: auto;
    }
}

@keyframes animatedMagnifier {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

div.header {
    .searchform {
        .submitknop {
            background-color: transparent;
            border: 0;
            display: block;
            height: 40px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            z-index: 203;

            span.rotate {
                animation: animatedMagnifier 1s linear infinite;
                background: url('/img/icons/loading.svg') center center no-repeat;
                background-size: 60%;
                display: block;
                height: 30px;
                width: 30px;

                #search-bar-icon {
                    display: none;
                }
            }
        }
    }
}

#search-fullpage-overlay {
    #zoek-form-value {
        border: 1px solid $color-secondary;
        border-radius: 118px;
        display: inline-block;
        height: 100%;
        padding: 10px 40px 10px 20px;

        &::placeholder,
        &:-ms-input-placeholder
        {
            color: $gray-lighter;
            opacity: 1;
        }

        &:focus {
            border: 1px solid $color-secondary;
        }
    }

    .search-button {
        background-color: transparent;
        position: absolute;
        right: 3px;
        top: 13px;
        width: 44px;
    }
}

ul.language_currency {
    background: url('/img/icons/world-darkblue.svg') no-repeat top 10px left;
    background-size: 20px;
    font-size: 14px;
    line-height: 40px;
    list-style: none;
    margin: 0 10px 0 0;
    padding-left: 30px;
    position: relative;
    z-index: 5;

    a {
        text-decoration: none;
    }

    > li {
        > a {
            padding: 0 20px 0 0;

            &.open::before {
                background-position: left bottom;
                top: 30%;
            }
        }

        > span::before {
            background: url('/img/icons/arrows-up-down-foris.svg') no-repeat left top;
            background-size: 10px;
            content: ' ';
            height: 10px;
            position: absolute;
            right: 0;
            top: 45%;
            width: 10px;
        }
    }

    ul {
        background-color: $white;
        display: none;
        filter: drop-shadow(0 0 5px $shadow-black-1);
        font-weight: 300;
        line-height: 24px;
        list-style: none;
        margin: 0;
        padding: 20px;
        position: absolute;
        right: -10px;
        top: 50px;
        width: 200px;
        z-index: 5;

        &::after {
            border-color: $white $white transparent transparent;
            border-style: solid;
            border-width: 10px;
            content: '';
            height: 0;
            position: absolute;
            right: 28px;
            top: 0;
            transform: rotate(-45deg);
            transform-origin: 0 0;
            width: 0;
        }
    }
}

.open_circle {
    border: 1px solid $gray-dark;
    border-radius: 100%;
    display: inline-block;
    height: 12px;
    margin: 0 5px 0 0;
    position: relative;
    top: 1px;
    width: 12px;
}

.dot.open_circle::before {
    background-color: $color-secondary;
    border-radius: 100%;
    content: '';
    display: block;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}

#product_selector {
    background-color: $white;
    border: 2px solid $color-secondary;
    border-radius: 10px;
    box-sizing: border-box;
    height: 190px;
    position: absolute;
    right: 10px;
    top: 50px;
    width: 250px;

    .titel {
        background: $button-color-primary url('/img/icons/search.svg') no-repeat 5px center;
        background-size: 20px 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: $font-color;
        font-size: 14px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        padding-left: 30px;
        text-transform: uppercase;
    }

    .filters {
        margin: 20px;
    }

    #priceslider {
        width: 170px;
    }
}

#sel_min {
    float: left;
    font-size: 11px;
}

#sel_max {
    float: right;
    font-size: 11px;
}

#sel_cat,
#sel_age {
    width: 100%;
}

#product_selector .ui-slider-horizontal {
    margin: 10px 0;
}

#product_selector .ui-slider-horizontal .ui-slider-handle:nth-of-type(1) {
    margin-left: -4px;
    width: 20px;
}

#product_selector .ui-slider-horizontal .ui-slider-handle:nth-of-type(2) {
    margin-left: -16px;
    width: 20px;
}

div.header_top_box {
    height: 40px;
    position: relative;
    width: 100%;
    z-index: 4;
}

li.certified {
    a {
        text-decoration: none;
    }

    .review-aggregate {
        .item,
        .reviews {
            display: none;
        }

        div.number {
            color: $font-color;
            float: left;
            line-height: 16px;
            margin-left: 5px;
        }

        div.star-rating {
            background: url('/img/icons/header/star-grijs.svg') repeat-x;
            background-size: 10px;
            height: 10px;
            width: 50px;

            .color {
                background: $gray-lighter url('/img/icons/header/star-geel.svg') repeat-x;
                background-size: 10px;
                height: 10px;
            }
        }
    }

    .votes-container {
        font-size: 11px;
        font-style: italic;

        strong {
            color: $gray-darkblue;
        }
    }
}


#side_navigation > .product_selector {
    background-color: $color-secondary;
    border-radius: 5px;
    margin-bottom: 20px;
    max-width: 400px;
    padding: 5px 15px 15px;

    @include media-breakpoint-up(lg) {
        max-width: none;
    }
}

.menu .product_selector {
    background-color: $button-color-primary;
    color: $font-color;
    margin: 0 0 20px;
    padding: 20px;

    h6 {
        color: $white;
    }
}

.highlight_box .product_selector {
    background-color: $button-color-primary;
    border-top: 5px solid $white;
    color: $font-color;
    margin-bottom: -500em;
    padding: 10px 0 500em;

    td {
        color: $white;
    }

    select {
        background: transparent url('/img/icons/arrows-up-down-white.svg') no-repeat right 5px top 10px;
        background-size: 12px;
        border: 1px solid $white;
        color: $white;
        padding: 5px;

        option {
            color: $black;
        }
    }

    .filters {
        box-sizing: border-box;
        padding: 0 20px;
    }
}

.product_selector {
    label {
        display: inline-block;
        float: none;
        width: auto;
    }

    .ui-slider-horizontal {
        border: 0;
        margin: 5px 6px;

        .ui-slider-handle {
            background-color: $white;
            border: 0;
            border-radius: 6px;
            cursor: pointer;
            height: 12px;
            position: absolute;
            width: 12px;
            z-index: 2;

            &:nth-of-type(1),
            &:nth-of-type(2) {
                margin: 4px 0 0 -6px;
            }
        }

        .ui-slider-range {
            background-color: $white;
            border: 0;
            display: block;
            font-size: 11px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
        }
    }

    .result_btn {
        background-color: $font-color;
        color: $button-color-primary;
        display: block;
        font-size: 12px;
        font-weight: bold;
        line-height: 30px;
        text-align: center;
        text-decoration: none;
    }
}

.highlight_box {
    .product_selector {
        .ui-slider-horizontal .ui-slider-handle {
            background-color: $white;
        }
    }
}


.menu .product_selector input,
.menu .product_selector select {
    width: 100%;
}

.week_sales {
    .item {
        float: left;
        margin: 0 10px 0 0;
        width: 220px;

        &:last-child {
            margin-right: 0;
        }

        .img {
            display: block;
            height: 150px;
            position: relative;
            text-align: center;

            img {
                bottom: 0;
                left: 0;
                margin: auto;
                max-height: 150px;
                max-width: 90%;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .name {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            font-size: 14px;
            font-weight: 300;
            -webkit-line-clamp: 2;
            line-height: 20px;
            margin: 0 0 3px;
            max-height: 40px;
            overflow: hidden;
            text-decoration: none;
            text-overflow: ellipsis;
            width: 100%;

            &:hover {
                text-decoration: underline;
            }
        }

        .price_stripe {
            color: $gray-light;
            display: inline;
            font-size: 13px;
            font-weight: 300;
            margin-right: 5px;
            text-decoration: line-through;
        }

        .price,
        .prijs,
        .tab_related .price {
            color: $font-color;
            font-size: 20px;
            font-weight: bold;
        }

        .korting.opruiming {
            background-color: $red-label;
            border-radius: 15px;
            color: $white;
            display: inline-block;
            font-size: 13px;
            line-height: 15px;
            margin-top: 10px;
            padding: 5px 12px;
            width: auto;
        }
    }
}

.bottommenu a.smiley {
    background: url('/img/icons/smile.svg') no-repeat left center;
    background-size: 20px;
    display: block;
    line-height: 20px;
    margin: 5px 0;
    padding: 0 0 0 30px;
}

.bottommenu div.star-rating {
    background: url('/img/icons/star-white-outline.svg') repeat-x;
    background-size: 14px;
    float: left;
    height: 14px;
    margin: 5px 0;
    width: 70px;
}

.bottommenu div.star-rating .color {
    background: $button-color-primary url('/img/icons/star-white.svg') repeat-x;
    background-size: 14px;
    height: 14px;
}

.bottommenu span.footer_rating {
    font-size: 13px;
    line-height: 24px;
    margin: 0 0 0 5px;
}

.footer_logos {
    display: table;
    margin: 40px auto 20px;
    width: auto;
}

.footer_paypal {
    color: $white;
    font-size: 14px;
    line-height: 50px;
    text-align: center;

    &::after {
        border: 10px solid transparent;
        border-top-color: $darkblue-paypal;
        content: ' ';
        left: 50%;
        margin-left: 70px;
        pointer-events: none;
        position: absolute;
        top: 100%;
    }

    a {
        color: $white;
    }
}


ul.sprite_logos {
    line-height: 30px;
    list-style: none;
    margin: 0;
    padding: 10px 0;
    text-align: center;

    li {
        color: $gray-light;
        display: inline-block;
        font-style: italic;
        margin: 0 10px 0 0;

        &:last-child {
            margin-right: 0;
        }
    }
}

ul.sprite_logos {
    a {
        background: no-repeat;
        background-size: 1200px 30px;
        display: block;
        height: 30px;
        overflow: hidden;

        &.ideal {
            background-position: -250px center;
            width: 35px;
        }

        &.paypal {
            background-position: -570px center;
            width: 118px;
        }

        &.mastercard {
            background-position: -55px center;
            width: 112px;
        }

        &.visa {
            background-position: -175px center;
            width: 66px;
        }

        &.klarna {
            background-position: -855px center;
            width: 45px;
        }

        &.bancontact {
            background-position: 0 center;
            width: 49px;
        }

        &.dhl {
            background-position: -710px center;
            width: 120px;
        }

        &.in3 {
            background-position: -952px center;
            width: 48px;
        }

        &.cartebancaire {
            background-position: -1030px center;
            width: 47px;
        }

        &.dotpay {
            background-position: -1100px center;
            width: 98px;
        }
    }
}


ul.keurmerk {
    float: right;

    li {
        margin: 0 0 0 10px;
    }
}

.shops_box {
    position: relative;
}

div.shops {
    display: block;
    font-size: 14px;
    height: 16px;
    list-style: none;
    position: absolute;
    top: 40px;
    z-index: 5;

    > a {
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        padding: 5px 20px 5px 0;
        position: relative;
        text-decoration: none;

        &.open {
            background-color: $white;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            left: 0;
            padding: 10px 20px;
            width: 260px;

            svg {
                display: none;
            }
        }
    }
}

ul.shops_menu {
    background-color: $white;
    display: none;
    height: 450px;
    left: 0;
    list-style: none;
    margin: 0;
    max-width: 100%;
    position: absolute;
    top: 36px;
    width: 260px;
    z-index: 99;

    > li:first-child {
        background-color: $white;
        padding-top: 10px;
    }

    > li > a {
        background-color: $white;
        display: block;
        font-size: 15px;
        font-style: normal;
        line-height: 45px;
        padding: 0 20px;
        text-decoration: none;
        white-space: nowrap;

        span {
            color: $color-primary;
            font-weight: bold;
            position: relative;
        }

        &:hover,
        &:hover > a {
            background-color: $color-secondary-light;
        }
    }

    li div.shop_box {
        background-color: $white;
        display: none;
        left: 260px;
        min-height: 630px;
        position: absolute;
        top: 0;
        width: 880px;
        z-index: -5;

        @media (max-width: 1157px) {
            width: 820px;
        }

        @media (max-width: 1097px) {
            width: 715px;
        }
    }

    > li:hover div.shop_box {
        display: block;
        overflow: hidden;
    }

    ul {
        box-sizing: border-box;
        float: left;
        list-style: none;
        margin: 0;
        padding: 20px 0 20px 20px;
        width: 25%;

        a {
            display: block;
            line-height: 20px;
            text-decoration: none;

            &:hover {
                color: $color-primary;
                text-decoration: underline;
            }
        }

        li.header:not(:first-child) {
            margin-top: 20px;
        }

        li.header {
            border-bottom: 0;
            margin-bottom: 10px;
            a {
                font-size: 16px;
                font-weight: bold;
            }
        }

        li strong a {
            margin-top: 10px;
        }
    }
}

div.top_attention_bar {
    background-color: $yellow-pale;
    position: relative;
    width: 100%;
    z-index: 4;

    .tab_box {
        line-height: 18px;
        margin: 0 auto;
        padding: 6px 32px 6px 12px;
        position: relative;
        text-align: center;

        a {
            color: $gray-dark;
            display: block;
            font-size: 12px;
            text-decoration: none;

            &.close {
                height: 12px;
                margin-top: -6px;
                position: absolute;
                right: 10px;
                top: 50%;
                width: 12px;

                img {
                    display: block;
                }
            }
        }
    }
}

#kiyoh {
    left: 760px;
    position: absolute;
    top: 112px;
}

.nb {
    background: url('#{$images-path}/icons/winkelwagen-white.svg') no-repeat 2px center;
    border: 1px solid $gray-lighter;
    cursor: pointer;
    font-size: 11px;
    left: 40px;
    line-height: 20px;
    padding: 2px 2px 2px 22px;
    position: absolute;
    top: 209px;
    width: 184px;
}

input.submit {
    background-color: $button-color-primary;
    border: 1px;
    color: $font-color;
    padding: 6px 30px;
}

input.submit_afrekenen {
    font-weight: bold;
    width: 200px;
}

.hmenu {
    background-color: $gray-lighter;
    padding: 20px;
    width: 210px;
}

ul.bottommenu li a.nbknop {
    text-decoration: underline;
}

.seo_txt {
    display: block;
    font-size: 12px;
    margin: 0 0 20px;
}

.seo_txt p {
    margin-bottom: 18px;
}

.seo_txt h2 {
    color: $font-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    padding: 0;
}

.seo_txt h3 {
    font-weight: bold;
}

.sale_header {
    img {
        display: block;
        margin-bottom: 10px;
    }

    ul {
        list-style: none;
        margin: 0;

        li {
            list-style: none;

            &:last-child {
                margin-right: 0;
            }

            a {
                border: 1px solid $gray-mid;
                box-sizing: border-box;
                display: block;
                font-size: 14px;
                padding: 15px 0;
                text-align: center;
                text-decoration: none;

                &:hover {
                    background-color: $gray-lighter;
                }

                span {
                    background: url('/img/icons/arrows-left-right.svg') no-repeat right top 6px;
                    background-size: 5px;
                    padding-right: 15px;
                }
            }
        }
    }
}


.merken_header {
    ul {
        list-style: none;
        margin: 20px 0 0;

        li {
            border: 1px solid $gray-lighter;
            box-sizing: border-box;
            float: left;
            list-style: none;
            margin: 0 12px 20px 0;
            width: 206px;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: block;
                font-size: 14px;
                padding: 15px 0;
                text-align: center;
                text-decoration: none;

                &:hover {
                    background-color: $gray-lighter;
                }

                span {
                    background: url('/img/icons/arrows-left-right.svg') no-repeat right top 6px;
                    background-size: 5px;
                    padding-right: 15px;
                }
            }
        }
    }

    table td {
        padding: 0;

        img {
            display: block;
        }
    }

    h2 {
        color: $gray-dark;
        font-size: 18px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: 600;
        margin-bottom: 10px;
    }

    p {
        padding: 0 !important;
    }

    .popular_products {
        overflow-x: scroll;
        position: relative;
        width: 100%;

        > div {
            white-space: nowrap;

            .psum {
                border: 1px solid $gray-lighter;
                margin-bottom: 10px;
                margin-right: 10px;
                padding: 10px;
                white-space: normal;
                width: 190px;

                &.padding_30 {
                    .highlight .inner {
                        padding-top: 0;
                    }
                }

                .naam a {
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    max-height: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .img {
                    height: 160px;

                    img {
                        max-height: 160px;
                    }
                }
            }
        }
    }
}

.tab_omschrijving .merken_header p:last-child {
    margin-bottom: 0 !important;
}

.banner {
    margin: 10px 0;
    width: 210px;
}

.footer_full {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;

    .bottom {
        margin: 0;
        padding: 20px 0;
        position: relative;
        z-index: 0;

        @include media-breakpoint-up(md) {
            padding: 50px 0;
        }
    }
}

.bottom_footer {
    background-color: $gray-lighter;
    padding: 0 20px;
}

.footer {
    font-size: 12px;
    line-height: 30px;
    margin-bottom: 30px;
    padding: 10px 0;
    text-align: center;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    small {
        font-size: 9px;

        a {
            color: $font-color;
            text-decoration: underline;

            &:hover {
                color: $color-primary-light;
            }
        }
    }
}

.footerright {
    color: $white;
    float: right;
    font-size: 11px;
    line-height: 40px;
    text-align: right;
}

.footerright a {
    color: $white;
    text-decoration: none;
}

.footer_wrapper {
    background-color: $color-secondary;
    line-height: 22px;
    width: 100%;
}

.abs_bottom {
    @include media-breakpoint-down(md) {
        margin-bottom: 74px;
    }
}

table.table_border {
    border-left: 1px solid $gray-lighter;
    border-top: 1px solid $gray-lighter;
}

table.table_border td {
    border-bottom: 1px solid $gray-lighter;
    border-right: 1px solid $gray-lighter;
    line-height: 18px;
    padding: 2px;
}

table.table_border td p {
    line-height: 18px;
}

ul#cat {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul#cat a {
    background: url('#{$images-path}/arrow_small.gif') no-repeat 0 7px;
    display: block;
    font-size: 12px;
    padding-left: 15px;
    text-decoration: none;
}

ul#cat a:hover {
    color: $font-color;
}

a.alfa {
    border: 1px solid $gray-lighter;
    display: block;
    float: left;
    font-size: 12px;
    font-weight: bold;
    margin-right: 2px;
    padding: 4px 2px;
    text-align: center;
    text-decoration: none;
    width: 22px;

    @include media-breakpoint-down(md) {
        line-height: 22px;
        margin-bottom: 10px;
        margin-right: 10px;
        width: 32px;
    }
}

a.alfa:hover {
    background-color: $gray-lighter;
}

a.alfa:last-child {
    margin-right: 0;
}

#overzicht_menu_hidden {
    display: none;
}

#overzicht_menu {
    ul {
        list-style: none;
        margin: 0 0 10px;
        padding: 0;

        li {
            margin: 0 0 10px;

            a {
                background-color: $gray-lighter;
                display: block;
                line-height: 20px;
                margin: 0;
                padding: 5px 15px;
                text-decoration: none;

                &:hover {
                    background-color: $gray-lighter;
                }
            }

            ul {
                display: block;

                li {
                    margin: 0;

                    a {
                        background: url('#{$images-path}/arrow_small.gif') no-repeat left 7px;
                        font-weight: normal;
                        line-height: 20px;
                        margin: 0;
                        padding: 0 0 0 15px;

                        &:hover {
                            background-color: transparent;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &.fietsen li a {
            border: 1px solid $gray-dce4e6;
            border-radius: 3px;
            font-size: 16px;
            line-height: 50px;
            padding: 0 0 0 15px;

            img {
                float: right;
                margin: -3px 0 0;
            }
        }
    }
}

.kolom {
    float: left;
    margin-left: 20px;
    width: 210px;
}

.kolom_merken {
    float: left;
    width: 210px;
}

.kolom_merken_marge {
    float: left;
    margin-left: 20px;
    width: 210px;
}

.txt_banner {
    margin: 0 0 20px;
}

#h2_slide {
    border: 1px solid $gray-lighter;
    border-top: 0;

    h2 {
        border-top: 1px solid $gray-lighter;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        padding: 0 10px;
        user-select: none;

        span {
            background: url('#{$images-path}/icons/arrows-up-down.svg') no-repeat right top 15px;
            background-size: 16px;
            display: block;
        }

        a {
            display: block;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            text-decoration: none;

            &:hover {
                color: $gray-dark;
            }
        }

        &:hover {
            color: $gray-dark;
        }
    }
}

div.h2_content {
    display: none;
}

div.h2_content div {
    padding: 10px;
}

.first.column {
    margin-right: 20px;
}

.last.column {
    margin-left: 20px;
}

ul.cat_visual {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border: 1px solid $gray-lighter;
        display: block;
        float: left;
        height: 170px;
        margin: 0 18px 40px 0;
        text-align: center;
        width: 175px;

        &:nth-child(6n+6) {
            margin-right: 0;
        }

        &:hover {
            border: 1px solid $gray-lighter;
            box-shadow: 0 0 10px 0 $shadow-black-1;
        }

        a.img {
            box-sizing: border-box;
            display: block;
            height: 150px;
            overflow: hidden;
            position: relative;
            text-align: center;
            width: 100%;

            img {
                bottom: 0;
                left: 0;
                margin: auto;
                max-height: 140px;
                max-width: 140px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        a.txt {
            background-color: $button-color-primary;
            color: $font-color;
            display: block;
            font-size: 13px;
            line-height: 28px;
            text-align: center;
            text-decoration: none;
            width: 100%;
        }
    }
}

.psum {
    box-sizing: border-box;
    color: $font-color;
    cursor: pointer;
    float: left;
    position: relative;

    .inner {
        position: relative;
    }

    .img {
        box-sizing: border-box;
        display: block;
        height: 230px;
        margin-bottom: 10px;
        overflow: hidden;
        padding: 5px;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 0;

        img {
            bottom: 0;
            left: 0;
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 0;
        }
    }

    .naam {
        position: relative;

        a {
            display: block;
            font-size: 14px;
            font-weight: 300;
            height: 40px;
            line-height: 20px;
            margin-bottom: 3px;
            overflow: hidden;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            span {
                display: block;
            }
        }
    }

    .adviesprijs {
        color: $font-color-grey;
        font-size: 14px;
        font-weight: 300;
        margin-right: 3px;
        text-decoration: line-through;

        &.orange {
            text-decoration-color: $orange;
        }
    }

    .prijs {
        color: $font-color;
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
    }

    .korting {
        background-color: $color-secondary;
        border-radius: 15px;
        color: $white;
        font-size: 13px;
        font-weight: 300;
        width: auto;
        padding: 0 10px;
        line-height: 24px;
        height: 24px;
        display: inline-block;

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: -27px;
            padding: 5px 12px;
            line-height: 14px;
            display: inline;
        }

        @include media-breakpoint-up(md) {
            top: -27px;
        }


        .opruiming {
            background-color: $red-primary;
        }
    }

    a.info {
        background-color: $orange;
        color: $white;
        display: block;
        margin: 0 auto;
        padding: 3px 5px;
        text-decoration: none;
        width: 125px;
    }

    a:hover.info {
        color: $gray-dark;
    }

    .favorite {
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;
        width: 16px;
        z-index: 1;

        a {
            background-image: url('#{$images-path}/icons/like_sprite.svg');
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            height: 100%;
            margin: 5px 0 0 5px;
            outline: none;
            text-indent: -9999px;
            width: 100%;
            z-index: 10;

            &:hover {
                background-position: center top;
            }

            .trashcan {
                background-image: url('#{$images-path}icons/trashcan.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 40%;
                border: 1px solid $gray-lighter;
                border-radius: 55px;
                display: inline-block;
                height: 55px;
                margin-top: 10px;
                outline: none;
                text-indent: -9999px;
                vertical-align: top;
                width: 55px;
                z-index: 1;

                &:hover {
                    border-color: $color-primary;
                }
            }
        }
    }

    .promo_box {
        overflow: hidden;
    }

    a.promo_amigo {
        background-size: cover;
        display: block;
        height: 347px;
        transition: all .1s ease-in-out;
        width: 100%;
    }

    .responsive {
        border-bottom: 1px solid $gray-lighter;

        @include media-breakpoint-up(sm) {
            border-bottom: 0;
        }

        .img {
            display: flex;
            margin: 0 auto;
            padding: 0;

            @include media-breakpoint-up(md) {
                height: 218px;
                width: 100%;
            }

            @include media-breakpoint-down(xs) {
                height: 120px;
                width: 120px;
            }

            img {
                max-height: 100%;
                max-width: 100%;
                position: inherit;
            }
        }
    }
}

.psum .prijs,
#producten_load .psum .prijs {
    color: $font-color;
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
}


.psum:hover a.settings,
div.cproduct:hover a.settings {
    display: block;
}

.pfoto span.opruiming {
    background-color: $orange;
    color: $white;
    font-size: 12px;
    left: 5px;
    line-height: 15px;
    padding: 2px 5px;
    position: absolute;
    text-transform: uppercase;
    top: 5px;
    width: auto;
    z-index: 1;

    &::before {
        border: 19px solid $orange;
        border-bottom: 19px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: ' ';
        font-size: 0;
        height: 0;
        position: absolute;
        right: -10px;
        top: 0;
        width: 0;
        z-index: -1;
    }
}

.lpg {
    background-color: $orange;
    color: $white;
    display: inline-block;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 5px;
    padding: 0 5px;
}

%baseCustomLabel {
    background-color: $green;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 5px;
    padding: 0 5px;
    position: relative;
}

.customLabel {
    @extend %baseCustomLabel;
}

.customLabelPlp {
    @extend %baseCustomLabel;
    border-radius: 76px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0;
    padding: 0 10px;
    height: fit-content;
}

.customLabelSmall {
    @extend %baseCustomLabel;
    border-radius: 76px;
    font-size: 13px;
    font-style: normal !important;
    font-weight: normal !important;
    padding: 0 10px !important;
    position: absolute;
    top: -34px;

    &.top {
        position: absolute;
        top: 10px;
        z-index: 2;
    }
}

.label-container-swiper-top {
    .customLabelSmall {
        top: 0;
    }
}

.countdown-label {
    background: $red-label url('https://assets.foris.shop/images/alarm-clock.svg') no-repeat left 8px bottom 50%;
    font-style: normal;
    height: 34px;
    line-height: 32px;
    margin-bottom: 10px !important;
    margin-left: 2px;
    padding-left: 35px;
    padding-right: 10px;
    text-align: center;
}

.tweedekansje {
    @extend %baseCustomLabel;
    background-color: $color-secondary;
    border-radius: 0;
    font-style: normal;
    font-weight: normal;

    @include media-breakpoint-up(sm) {
        position: absolute;
        top: 0;
        z-index: 2;
    }
}

.plp-usps {
    margin-bottom: 0;
    margin-left: 17px;
}

span.drager_lift_s {
    background-color: $green;
    border-radius: 35px;
    box-sizing: border-box;
    color: $white;
    font-size: 9px;
    height: 70px;
    left: 5px;
    line-height: 12px;
    padding: 23px 0;
    position: absolute;
    text-align: center;
    top: 5px;
    width: 70px;
    z-index: 1;
}

span.kortingbol_s {
    background-color: $button-color-primary;
    border-radius: 40px;
    box-sizing: border-box;
    color: $font-color;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    height: 80px;
    justify-content: center;
    line-height: 14px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 5px;
    width: 80px;
    z-index: 1;
}

a.settings {
    display: none;
    margin-left: -8px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
}

a:hover.promo_amigo {
    transform: scale(1.08);
}

a.wishlist_box {
    border-radius: 29px;
    display: block;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    margin: 0;
    padding: 10px;
    text-align: center;
    text-decoration: none;
}

a.wishlist_box span {
    background-size: 20px;
    display: inline-block;
    padding-left: 30px;
}

.favorite-on {
    background: url('/img/icons/like_on-foris.svg') no-repeat;
}

.favorite-border-on {
    border: 1px solid $color-secondary;
}

.favorite-off {
    background: url('/img/icons/like_off-foris.svg') no-repeat;
}

.favorite-border-off {
    border: 1px solid $ui-line-divider-grey;
}

a:hover.wishlist_box {
    color: $orange;
}

.opisop {
    background-color: $orange;
    border-radius: 20px;
    color: $white;
    font-size: 10px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 5px;
    transform: rotate(10deg);
    width: 40px;
    z-index: 1;
}

.clubs .img {
    height: 160px;
}

.clubs .naam a {
    font-size: 12px;
    height: auto;
    text-transform: uppercase;
}

.cproduct {
    margin: 0 20px 0 0;
    position: relative;
    width: 100%;
}

.cproduct h1 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
    }
}

.cproduct .h1_footer {
    margin: 0 0 10px;
}

.cproduct .h1_footer div {
    float: left;
    font-size: 14px;
    margin: 0;
}

.cproduct .h1_footer div a {
    color: $color-primary-light;
    text-decoration: none;
}

.cproduct .h1_footer div a:hover {
    text-decoration: underline;
}

.cproduct_left {
    float: left;
    margin: 0 20px 0 0;
    width: 730px;
}

.cproduct_left p {
    padding: 20px 0 0;
}

.cproduct_left p a:hover {
    color: $color-primary-light;
}

.cproduct_right {
    box-shadow: 0 0 5px 0 $shadow-black-1;
    float: left;
    width: 390px;
}

.pdp_stock {
    color: $green;
    font-size: 14px;
    margin: 10px 0 5px;
}

.related_items,
.accessories_items,
.highlighted_items {
    margin: 40px 0 0;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;

    > .pc_slides {
        margin: 20px 20px 0;
        overflow: hidden;
        white-space: nowrap;

        * {
            white-space: initial;
        }
    }
}

.pc_slides {
    color: $font-color;
}

.related_items .pc_slides .psum,
.accessories_items .pc_slides .psum,
.highlighted_items .pc_slides .psum {
    overflow: hidden;
    white-space: normal;
}

.related_items a.arrow,
.accessories_items a.arrow,
.highlighted_items a.arrow {
    height: 30px;
    position: absolute;
    top: 180px;
    width: 20px;
    z-index: 2;
}

.related_items a.arrow.pc_back,
.accessories_items a.arrow.pc_back,
.highlighted_items a.arrow.pc_back {
    background: $white url('/img/icons/arrows-left-right-darkblue.svg') no-repeat center bottom 6px;
    background-size: 10px;
    cursor: pointer;
    left: 0;
}

.related_items a.arrow.pc_next,
.accessories_items a.arrow.pc_next,
.highlighted_items a.arrow.pc_next {
    background: $white url('/img/icons/arrows-left-right.svg') no-repeat center top 6px;
    background-size: 10px;
    cursor: pointer;
    right: 0;
}

.related_items a.arrow:hover,
.accessories_items a.arrow:hover,
.highlighted_items a.arrow:hover {
    background-color: $gray-lighter !important;
}

#cbar_widget0,
#cbar_widget1 {
    margin: 60px 0 -60px;
}

a.product_tab {
    background: $gray-lighter url('/img/icons/arrows-up-down.svg') no-repeat right 10px bottom 11px;
    background-size: 14px;
    color: $gray-dark;
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin: 40px 0 20px;
    padding: 8px 10px;
    text-decoration: none;
}

#overzicht_attr {
    margin: 20px 0 40px;
}

#overzicht_attr table tr:nth-child(odd) {
    background-color: $color-secondary-light;
}

#overzicht_attr table tr td {
    font-size: 12px;
    padding: 10px 5px;

    @include media-breakpoint-up(md) {
        font-size: 14px;
    }

    @include media-breakpoint-up(lg) {
        padding: 10px;
    }
}

div.tabbox {
    margin: 0 0 40px;
}

.box_sc {
    border: 1px solid $gray-lighter;
    padding: 20px;
    position: relative;
}

.box_sc p,
.box_sc .tweedekansje {
    left: 0;
    margin: 0 !important;
    top: 0;
}

.pfoto {
    margin-bottom: 30px;
    position: relative;
}

.pfoto .main_img {
    cursor: zoom-in;
    display: block;
    height: 200px;
    position: relative;
    text-align: center;

    @include media-breakpoint-up(md) {
        height: 250px;
    }

    @include media-breakpoint-up(lg) {
        height: 400px;
    }
}

.zoomin {
    background: url('/img/icons/zoom-in.svg') no-repeat center left;
    background-size: 16px;
    bottom: 0;
    color: $gray-dark;
    font-size: 14px;
    left: 0;
    line-height: 20px;
    padding-left: 20px;
    pointer-events: none;
    position: absolute;
    z-index: 2;
}

.pfoto .main_img img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.pfoto .thumb {
    margin: 30px 0 0;
}

.pfoto .thumb a {
    aspect-ratio: 1 / 1;
    border: 1px solid $gray-lighter;
    border-radius: 4px;
    cursor: pointer;
    @supports not (aspect-ratio: auto) {
        height: 32px !important;

        @include media-breakpoint-up(md) {
            height: 70px !important;
        }
    }
    display: block;
    margin-right: min(10px);
    max-height: 100px;
    max-width: 100px;
    overflow: hidden;
}

.pfoto .thumb a:hover {
    border: 1px solid $color-secondary;
}

.pfoto .thumb img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.pfoto .thumb .overlay {
    background: $shadow-black-3;
    bottom: 0;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    left: 0;
    line-height: 100px;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
}

.pfoto a {
    color: $gray-light-blue;
}

.pfoto a.inkijkexemplaar {
    color: $font-color;
    display: block;
    font-size: 14px;
    margin: 10px 0 20px;
    text-decoration: none;
}

.pfoto a.inkijkexemplaar span {
    text-decoration: underline;
}

.ptext {
    padding: 20px 0;
    width: 100%;
}

.ptext .color {
    color: $font-color;
}

.pprijs {
    margin: 0 0 20px;
}

.pprijstop {
    color: $ui-line-divider-grey;
    font-size: 14px;
    line-height: 16px;
    padding: 0 10px 5px 0;
    position: relative;
}

.pprijstop span {
    text-decoration: line-through;
}

.pprijsmiddle {
    color: $font-color;
    float: left;
    font-size: 26px;
    font-weight: bold;
    line-height: 26px;
    white-space: nowrap;
}

.pprijsmiddle .vat-container {
    color: $gray-dark !important;
    font-size: 12px !important;
    font-weight: normal !important;
}

.pprijskorting {
    background-color: $color-secondary;
    border-radius: 15px;
    color: $white;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    padding: 0 12px;
    position: relative;
    top: -3px;
}

.pprijskorting.opruiming {
    background-color: $red-label;
}

.savemoney {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
}

.pprijswinkel {
    border-top: 1px solid $gray-lighter;
    margin-top: 5px;
    padding: 5px 0 0;
}

.cproduct_right select {
    background-position: right 10px center;
    margin-bottom: 10px;
    padding: 10px 20px 10px 10px;
    width: 100%;
}

.cproduct_right .size_select {
    position: relative;
}

.cproduct_right .size_select.arrow::before {
    border: 15px solid transparent;
    border-left-color: $red-secondary;
    content: '';
    height: 0;
    left: -20px;
    position: absolute;
    top: 5px;
    width: 0;
    z-index: 1;
}

.sizebox {
    position: relative;
}

.sizebox p {
    margin-bottom: 5px;
}

.pcolors_box {
    margin-bottom: 5px;
}

.select_header {
    color: $font-color;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

.size_select label.option {
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 6px 5px 0;
    padding: .5rem 1rem;
}

.size_select label.option.selected {
    background-color: $shadow-blue;
    border-color: $color-secondary;
}

.stock-label {
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 6px 5px 0;
    padding: .5rem 1rem;
}

.stock-label.option.inStock {
    border-color: $color-secondary;
    color: $color-secondary;
}


.stock-label.option.AlmostOutOfStock {
    background-color: $shadow-orange;
    border-color: $orange;
    color: $orange;
}

.stock-label.option.outOfStock {
    background-color: $shadow-red;
    border-color: $red-label;
    color: $red-label;
    margin: 6px 0 5px 0;
}

a.pcolors {
    border: 1px solid $ui-line-divider-grey;
    border-radius: 3px;
    box-sizing: border-box;
    float: left;
    height: 50px;
    margin: 0 9px 5px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    width: 50px;

    &:hover {
        border-color: $color-secondary;
    }
}

.size_select a.option:hover {
    border-color: $color-secondary;
}

.size_select a.option.disabled:hover {
    border-color: $ui-line-divider-grey;
}

a.pcolors:nth-child(6n) {
    margin-right: 0;
}

a.pcolors img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

a.pcolors.selected {
    border-color: $color-secondary;
}

a.pcolors.selected::after {
    background-color: $color-secondary-shadow;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.video_btn {
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
}

.video_btn img {
    filter: drop-shadow(0 0 3px $shadow-black-2);
    max-height: 35px !important;
    padding: 5px 0;
}

ul.usp_pdp {
    background-color: $color-secondary-light;
    list-style: none;
    margin: 0 !important;
    padding: 20px;
}

ul.usp_pdp li {
    background-image: url('/img/icons/check_green_foris.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    display: block;
    line-height: 18px;
    padding: 0 0 10px 25px;
}

ul.usp_pdp li a {
    text-decoration: none;
}

ul.usp_pdp li a:hover {
    color: $gray-dark;
}

ul.usp_pdp li:last-child {
    padding-bottom: 0;
}

.voorraad {
    background: url('/img/icons/voorraad.svg') no-repeat;
    background-size: 16px 16px;
    display: inline-block;
    font-size: 11px;
    line-height: 16px;
    margin: 0 0 4px;
    padding: 0 0 0 20px;
}

.voorraad-product,
.voorraad-product a {
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
}

a.nostocktip {
    color: $gray-mid;
    font-size: 11px;
    position: relative;
    text-decoration: none;
}

a.nostocktip span {
    background-color: $white;
    border: 1px solid $red-label;
    bottom: 10px;
    display: none;
    font-size: 12px;
    font-weight: normal;
    height: 84px;
    left: -120px;
    line-height: 16px;
    padding: 10px;
    position: absolute;
    text-decoration: none;
    top: 15px;
    width: 240px;
    z-index: 100;
}

.werk {
    border: 1px solid $red;
    margin-bottom: 20px;
    padding: 5px;
}

#post_product_submit {
    font-size: 14px;
    max-width: 800px;
}

#post_product_submit .topbanner {
    background: $color-secondary-semi-transparent-secondary url('/img/icons/check_green_foris.svg') no-repeat left 20px center;
    background-size: 20px;
    color: $font-color;
    display: block;
    line-height: 20px;
    padding: 10px 0 10px 50px;
}

#post_product_submit a {
    border-radius: 3px;
    display: inline-block;
    font-size: 16px;
    line-height: initial;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

#post_product_submit {
    a.opt1 {
        border: 1px solid $gray-dark;
        border-radius: 74px;
        margin-top: 10px;
        padding: 10px;

        &:hover {
            border-color: $color-primary-light;
            color: $color-primary-light;
        }
    }

    a.opt2 {
        background-color: $button-color-primary;
        border-radius: 74px;
        box-shadow: 0 2px 2px 0 $shadow-black-2;
        color: $font-color;
        padding: 10px;
        transition: all .1s;

        &:hover {
            background-color: $button-color-primary-hover;
            transition: all .1s;
        }
    }

    #pps_img {
        height: 120px;
        text-align: center;
        width: 150px;

        img {
            display: inline-block;
            max-height: 100%;
            max-width: 100%;
        }
    }
}

#post_product_submit #extra_info_submit p {
    background: $light-pink url('/img/icons/exclamation.svg') no-repeat left 20px top 10px;
    background-size: 20px;
    color: $red-secondary;
    display: block;
    line-height: 20px;
    margin-top: 20px;
    padding: 10px 0 10px 50px;
}

a.adviesprijs_tip {
    position: relative;
    text-decoration: none;
    top: -1px;
}

a.adviesprijs_tip span {
    display: none;
}

a.adviesprijs_tip:hover span {
    background-color: $white;
    border: 1px solid $gray-darkest;
    bottom: 10px;
    color: $gray-dark;
    display: block;
    font-size: 12px;
    font-weight: 300;
    left: -5px;
    line-height: 16px;
    margin: 10px;
    padding: 10px;
    position: absolute;
    text-decoration: none;
    width: 250px;
    z-index: 10000;
}

#maps {
    border: 1px solid $gray-lighter;
    font-size: 11px;
    height: 350px;
    line-height: 16px;
    width: 668px;
}

#map_txt {
    overflow: hidden;
}

#errorbox,
.errorbox {
    border: 1px solid $red;
    color: $red;
    display: none;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    padding: 0 10px;
}

#errorbox label,
.errorbox label {
    float: none;
    list-style: none;
    margin: 0;
    padding: 0;
    width: auto;
}

#errorbox li,
.errorbox li {
    list-style: none;
    margin: 0;
    padding: 3px 0;
}

.errorbox li a {
    color: $red-secondary;
    font-weight: bold;
}

#successbox {
    border: 1px solid $green;
    color: $red;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    padding: 0 10px;
}

#successbox li {
    color: $green;
    list-style: none;
    margin: 0;
    padding: 3px 0;
}

h2.contact {
    font-size: 20px;
}

#contact_form input,
#contact_form textarea,
#contact_form select {
    display: block;
    margin: 2px 0 10px;
}

.submit_contact {
    appearance: none;
    background: $button-color-primary linear-gradient($button-color-primary, $button-color-primary-hover);
    border: 0;
    border-radius: 0;
    color: $font-color;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    margin: 20px 0 0;
    padding: 0 20px;
    text-transform: uppercase;
}

.contact_l {
    float: left;
    margin-right: 40px;
    width: 335px;
}

.contact_r {
    float: left;
    width: 335px;
}

a.reactiebutton {
    background-color: $button-color-primary;
    color: $font-color;
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    text-decoration: none;
}

a:hover.reactiebutton {
    color: $gray-dark;
}

.keuzewijzer span {
    display: inline-block;
    width: 75px;
}

.keuzewijzer {
    font-size: 13px;
    font-weight: 300;
    margin: 10px 0;

    a {
        border-radius: 3px;
        color: $color-primary-light;
        display: inline-block;
        font-size: 14px;
        margin-bottom: 5px;
        margin-right: 10px;
        margin-top: 5px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.keuzewijzer a:hover {
    color: $color-primary;
}

#box_betalen {
    background: url('#{$images-path}/sprite_home.png') no-repeat 175px -155px;
    border: 1px solid $gray-blue;
    color: $darkblue;
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
    padding: 15px 15px 35px;
}

#box_betalen ul {
    color: $color-secondary;
    font-size: 12px;
    line-height: 18px;
    list-style: url('#{$images-path}/bullit_oke.gif');
    margin-top: 10px;
}

#box_betalen ul li {
    margin-top: -2px;
}

#bezorgadres,
#bezorgservicepoint {
    display: none;
}

tr.border td {
    border-top: 1px solid $gray-lighter;
}

.border_top {
    border-top: 1px solid $color-secondary;
    padding-top: 20px;
}

.button_volgend {
    background-color: $button-color-primary;
    border: 0;
    color: $font-color;
    float: right;
    font-weight: bold;
    padding: 3px 0;
    width: 200px;
}

.button_vorig {
    background-color: $gray-lighter;
    border: 0;
    color: $gray-dark;
    padding: 3px 0;
    width: 100px;
}

.infobox {
    position: relative;
    top: 4px;
}

.actiecodeok {
    display: none;
}

#min {
    float: left;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    text-align: left;
    width: 80px;
}

#max {
    float: right;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    text-align: right;
    width: 80px;
}

#producten_load {
    margin: 20px 0;
    min-height: 100px;
    position: relative;
}

.producten_overlay {
    filter: blur(3px);
    opacity: .5;
}

.producten_box_loader::before {
    background: url('/img/loader.gif') center center no-repeat $white;
    border-radius: 24px;
    box-shadow: 0 0 5px 0 $shadow-black-1;
    content: ' ';
    height: 48px;
    left: 50%;
    margin-left: -24px;
    position: absolute;
    top: 0;
    width: 48px;
}

#producten_load div.attr_labels {
    font-size: 13px;
    margin-bottom: 5px;
}

#producten_load div.attr_labels span {
    margin-right: 10px;
}

#producten_load a.attr_label {
    background: url('/img/icons/cross.svg?cache=20180529') $gray-lighter no-repeat right 6px center;
    background-size: 6px;
    border: 1px solid $gray-lighter;
    display: inline-block;
    font-size: 13px;
    margin: 0 10px 5px 0;
    padding: 6px 18px 6px 6px;
    text-decoration: none;
}

#producten_load a:hover.attr_label {
    text-decoration: line-through;
}

.geenverzendkosten {
    color: $font-color;
    font-size: 8px;
    font-weight: bold;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
}

// SLIDER
.ui-widget-content {
    border: 1px solid $gray-light;
    color: $near-black;
}

.ui-widget-content a {
    color: $near-black;
}

.ui-widget-header {
    border: 1px solid $gray-light;
    color: $near-black;
    font-weight: bold;
}

.ui-widget-header a {
    color: $near-black;
    text-decoration: none;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
    border: 0;
    color: $gray-mid-dark;
    font-weight: normal;
    outline: none;
}

.ui-slider {
    position: relative;
    text-align: left;
}

.ui-slider .ui-slider-handle {
    cursor: default;
    height: 20px;
    position: absolute;
    width: 11px;
    z-index: 2;
}

.ui-slider .ui-slider-range {
    border: 0;
    display: block;
    font-size: 11px;
    position: absolute;
    z-index: 1;
}

.ui-slider-horizontal {
    height: 2px;
    margin: 10px 5px;
}

.ui-slider-horizontal .ui-slider-handle {
    margin-left: -6px;
    top: -9px;
}

.ui-slider-horizontal .ui-slider-range {
    height: 100%;
    top: 0;
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

// AUTOCOMPLETE SEARCH
ul.search-autocomplete.ui-widget-content {
    border-top: 0;
}

ul.search-autocomplete {
    background-color: $white;
    box-shadow: 0 0 5px $blue-semitransparent;
    box-sizing: border-box;
    list-style: none;
    width: 400px !important;
    z-index: 999;
}

ul.search-autocomplete li div {
    color: $gray-dark;
    padding: 10px 15px;
}

ul.search-autocomplete li:hover,
ul.search-autocomplete li div.ui-state-active {
    background-color: $gray-lighter;
    cursor: pointer;
}

.pagination_box {
    border-bottom: 1px solid $gray-lighter;
    font-size: 13px;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.sort_products {
    font-weight: 300;
}

.sort_products_box span {
    display: inline-block;
    padding-right: 1rem;
}

// INDEXERING
.indexering a {
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;

    line-height: 32px;
    margin: 0 0 0 5px;
    min-width: 32px;
    text-align: center;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
        line-height: 26px;
        min-width: 28px;
    }
}

.indexering a.prev,
.indexering a.next {
    background: url('/img/icons/arrows-left-right.svg') no-repeat center;
    background-size: 6px;
    text-indent: -10000px;
}

.indexering a.prev {
    background-position: center bottom 11px;

    @include media-breakpoint-up(lg) {
        background-position: center bottom 8px;
    }
}

.indexering a.next {
    background-position: center top 11px;

    @include media-breakpoint-up(lg) {
        background-position: center top 8px;
    }
}

.indexering a:hover {
    background-color: $gray-lighter;
}

.indexering a.active {
    background-color: $color-secondary;
    color: $white;
}

.indexering span {
    display: inline-block;
    font-size: 13px;
    line-height: 30px;
    margin: 0 0 0 5px;
}

// 404 PAGE
.error_404_img {
    background: url('/img/404.svg') no-repeat center center;
    background-size: contain;
    height: 400px;
    margin: 40px 0 0;
    padding: 0 0 20px;
    position: relative;
    text-align: center;
    width: 100%;
}

.error_404_img h1 {
    bottom: 20px;
    color: $blue-gray;
    font-size: 80px;
    font-weight: bold;
    margin: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.error_404_box {
    padding: 40px 20px;
    text-align: center;
}

.error_404_box h2 {
    color: $blue-gray;
    font-size: 20px;
    margin: 0 0 20px;
}

.error_404_box p {
    padding: 0 150px;

    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.error_404_box .fullsubmit {
    margin: 0 auto;
    padding: 0 20px;
    text-transform: inherit;
    width: auto;
}

// CART
hr.fullcart {
    background-color: $gray-lighter;
    border: 0;
    height: 1px;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

a.continue_shopping {
    border: 2px solid $gray-dark;
    border-radius: 74px;
    display: inline-block;
    font-size: 14px;
    height: 45px;
    line-height: 41px;
    margin: 0 20px 0 0;
    text-align: center;
    text-decoration: none;
    width: 300px;

    &:hover {
        border: 2px solid $color-primary-light;
        color: $color-primary-light;
    }
}

input.fullsubmit,
button.fullsubmit {
    appearance: none;
    background-color: $button-color-primary;
    border: 0;
    border-radius: 74px;
    box-shadow: 0 1px 2px 0 $shadow-black-1;
    color: $font-color;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    height: 43px;
    overflow: hidden;
    padding: 10px 20px;
    transition: all .1s;
}

input.fullsubmit:disabled {
    background-color: $gray-lighter;
}

.fullsubmit:hover {
    background-color: $button-color-primary-hover;
    transition: all .1s;
}

input.smallsubmit {
    appearance: none;
    background-color: $gray-dark;
    border: 1px solid $gray-dark;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 $shadow-black-2;
    color: $white;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 10px 20px;
    transition: all .1s;
}

.smallsubmit:hover {
    background-color: $near-black;
    border: 1px solid $near-black;
    transition: all .1s;
}

.button-contactform-send {
    background-color: $button-color-primary;
    border: 0;
    border-radius: 50px !important;
    box-shadow: 0 2px 2px 0 $shadow-black-2 !important;
    color: $font-color;
    display: inline-block;
    font-size: 16px;
    line-height: 40px;
    margin-top: 17px;
    padding: 0 10px;
    text-decoration: none;
    transition: all .1s;

    &:hover {
        background-color: $button-color-primary-hover !important;
        color: $font-color !important;
        text-decoration: underline;
    }
}

.wishsubmit {
    background: $button-color-primary url('/img/icons/winkelwagen-foris.svg') no-repeat right 20px center;
    background-size: 25px;
    border: 0;
    border-radius: 40px;
    box-shadow: 0 2px 2px 0 $shadow-black-2;
    color: $font-color;
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    height: 55px;
    line-height: 55px;
    margin: 10px 0 0;
    overflow: hidden;
    padding: 0 0 0 39px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .1s;
    vertical-align: top;
    width: 55px;
}

.wishsubmit:hover {
    background-color: $button-color-primary-hover;
    color: $font-color;
    text-decoration: none;
}

.btn-login-submit {
    appearance: none;
    background-color: $button-color-primary;
    border: 0;
    border-radius: 45px;
    box-shadow: 0 2px 2px 0 $shadow-black-2;
    color: $font-color;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 400;
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    padding: 0 20px;
    text-align: center;
    text-decoration: none;
    transition: all .1s;
    width: 100%;
}

.btn-login-submit:hover {
    background-color: $button-color-primary-hover;
    color: $white;
    transition: all .1s;
}

#seomerk {
    margin-top: 20px;
}

#seomerk p {
    color: $gray-mid;
    margin-bottom: 18px;
}

#seomerk h2 {
    color: $gray-dark;
}

// ONE STEP CHECKOUT
.nobg {
    background-image: none;
}

.osco_form {
    min-height: 200px;
    padding: 20px 0 90px;
}

.osco_form .checkbox {
    border: 0;
    margin: 0 10px 0 0;
    padding: 0;
    position: relative;

    &.betaalmethode {
        position: absolute;
    }
}

.osco_form .border td {
    border-top: 1px solid $gray-dark;
}

.osco_form #cart tr td {
    line-height: 26px;
}

.osco_form label,
#contact_form label {
    display: inline-block;
    float: none;
    width: auto;
}

.osco_form input,
.osco_form textarea,
.osco_form select {
    margin-bottom: 10px;
    resize: none;
}

.osco_form input.error {
    background-color: $white-orange;
    border: 1px solid $orange;
}

.osco_form label.error,
.osco_form div.error,
#contact_form label.error,
.return_procedure label.error,
.return_procedure div.error,
.nbform label.error {
    color: $orange;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: -10px 10px 10px 0;

    a {
        color: $orange;
    }
}

.return_procedure label.error {
    margin-top: 5px;
}

.osco_form input[type=text].valid,
.osco_form input[type=tel].valid,
.osco_form input[type=email].valid,
.osco_form select.valid,
input:not([type="button"]):not([type="radio"]).valid {
    background: $white url('/img/icons/voorraad.svg') no-repeat right 10px center;
    background-size: 14px;
    border: 1px solid $gray-lighter;
}

.postcode_warning {
    color: $red;
}

#loginform td label.error,
#forgot_password td label.error {
    display: none !important;
}

.osco_form select.gb {
    padding-right: 15px;
}

.osco_form td {
    line-height: 26px;
}

.osco_form .pm {
    display: none;
    height: 35px;
}

.osco_form .pm td {
    line-height: 35px;
}

.osco_form .pm td select {
    padding: 6px 34px 6px 6px;
}

.osco_form h1 {
    clear: both;
    margin: 0 0 5px;
    padding: 0 0 5px;
}

.osco_form a {
    color: $color-primary;
}

.osco_form a:hover {
    color: $color-primary-light !important;
}

.osco_form .land {
    height: 40px;
}

.osco_form .ob {
    margin: 6px 0 0;
}

.osco_form .seperator {
    margin-right: 60px;
}

.osco_form .button_bestel {
    appearance: none;
    background: $button-color-primary url('/img/icons/winkelwagen-foris.svg') no-repeat right 20px center;
    border: 0;
    border-radius: 0;
    color: $font-color;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    margin: 20px 0 0;
    padding: 0;
    text-transform: uppercase;
    width: 190px;
}

.osco_form .button_bestel:hover {
    background-color: $color-primary;
}

#osco_left {
    float: left;
    min-height: 800px;
    width: 340px;
}

#osco_right {
    float: left;
    width: 740px;
}

#osco_right #gegevens label.pm_label {
    display: block;
}

#osco_right #gegevens label span {
    clear: right;
    color: $gray-lighter;
    float: right;
}

.osco_form #osco_total td {
    height: 20px;
    line-height: 20px;
}

.table23 td {
    height: 23px !important;
    line-height: 23px !important;
}

.osco_column_float {
    float: left;
    min-height: 280px;
    padding: 0 0 20px;
    width: 340px;
}

.payment_options {
    background: url('#{$images-path}/betaalmethode-sprite.png') no-repeat left 0;
    display: inline-block;
    height: 30px !important;
    line-height: 30px !important;
    overflow: hidden;
    padding: 0 0 0 50px;
    width: auto;
}

#issuerID {
    display: none;
    margin: 0;
}

.osco_form select.error {
    background-color: $white-orange;
    border: 1px solid $orange;
    padding: 9px 20px 9px 9px;
}

.osco_form .zakelijk_fields {
    display: none;
}

.osco_form #errorbox {
    background-color: $white-orange;
    border: 0;
    color: $red;
    padding: 10px;
}

.warning_box {
    background-color: $white-orange;
    border: 1px solid $orange;
    color: $gray-dark;
    margin-top: 10px;
    padding: 10px;
}

.osco_form #osco_overlay {
    background: url('#{$images-path}/loader.gif') right 3px no-repeat $white;
    display: none;
    filter: alpha(opacity = 80);
    left: 0;
    opacity: .8;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 110;
    zoom: 1;
}

#cartsummary {
    min-height: 135px;
}

tr.paypal_return_info td {
    line-height: 18px;
    padding: 5px;
}

tr.paypal_return_info td a {
    color: $gray-dark;
}

tr.paypal_return_info td:last-child {
    background: $gray-lighter url('/img/betaalmethoden/paypal.svg') no-repeat right 5px bottom 5px;
    background-size: 60px;
}

div.douaneheffingen {
    border: 1px dashed $gray-light;
    color: $gray-light;
    display: none;
    margin-top: 10px;
    padding: 10px;
}

div.douaneheffingen p {
    color: $gray-light;
}

.login_txt {
    background-color: $white-yellow;
    font-size: 14px;
    padding: 10px;
}

#dhlpoints_content {
    padding: 20px;

    @include media-breakpoint-down(md) {
        height: 80% !important;
        width: 80% !important;
    }

    @include media-breakpoint-down(sm) {
        height: 100% !important;
        width: 100% !important;
    }

    .sp_pc_zip,
    #sp_pc {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }

    #parcelbox {
        width: 900px;

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }

    .smallsubmit {
        background-color: $gray-dark !important;
    }
}

#parcel_addr .gradient {
    background-color: $gray-lighter;
}

#parcel_addr table {
    border-top: 1px solid $gray-lighter;
}

#parcel_addr table td {
    border-bottom: 1px solid $gray-lighter;
    padding: 5px 0;
}

#parcel_addr table table {
    border: 0;
}

#parcel_addr table table td {
    border: 0;
    font-family: $font-family !important;
    font-size: 11px !important;
    padding: 0 5px 0 0;
}

#parcel_addr {
    float: left;
    height: 400px;
    width: 380px;

    @include media-breakpoint-down(md) {
        height: 100% !important;
        width: 100% !important;
    }
}

#parcel_maps {
    float: left;
    height: 350px;
    margin: 0 0 0 20px;
    width: 500px;

    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

#parcelshop_info {
    font-size: 12px;
    font-style: italic;
    padding-left: 25px;
}

#loginbox,
#forgot_password {
    display: none;
}

#login {
    max-width: 100%;
    padding: 60px 0 0;
    width: 420px;
}

#login .table_padding,
#forgot_password .table_padding {
    margin: 0 30px;
}

#login #loginform table {
    width: 100%;
}

#login_status,
#forgot_status {
    display: none;
    width: 100%;
}

.maskww {
    background: url('/img/icons/viewhide.svg') no-repeat center 8px;
    background-size: 20px;
    box-sizing: border-box;
    content: '';
    cursor: pointer;
    height: 31px;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 31px;
    z-index: 1;
}

.ww_weak,
.ww_good,
.ww_strong {
    background-color: $white;
    display: inline-block;
    font-size: 11px;
    line-height: 20px;
    margin: 2px 0 0;
    padding: 3px;
    text-decoration: none;
    text-transform: lowercase;
}

a.ww_weak,
a:hover.ww_weak {
    border: 1px solid $red-dark;
    color: $red-dark;
}

a.ww_good,
a:hover.ww_good {
    border: 1px solid $orange-dark;
    color: $orange-dark;
}

a.ww_strong,
a:hover.ww_strong {
    border: 1px solid $green;
    color: $green;
}

.review-aggregate {
    position: relative;
}

.review-aggregate .stars {
    float: right;
    margin: 22px 20px 0 0;
}

.review-aggregate .stars .star {
    background: url('/img/stars.png') no-repeat;
    display: inline-block;
    height: 26px;
    overflow: hidden;
    width: 27px;
}

.review-aggregate .stars .star_grey {
    background-position: 0 -26px;
}

.review-aggregate .item {
    display: block;
    padding-bottom: 20px;
}

.review-aggregate .item .fn {
    display: inline-block;
}

.review-aggregate .item .photo {
    left: 1px;
    position: absolute;
    top: 55px;
}

.review-aggregate .bluebar {
    background-color: $color-primary;
    height: 52px;
    padding: 10px 20px;
}

.review-aggregate .bluebar p {
    padding-top: 10px;
}

.review-aggregate .bluebar .rating {
    color: $white;
    display: block;
}

.review-aggregate .bluebar .rating .average,
.review-aggregate .bluebar .rating .best {
    font-size: 24px;
    font-weight: bold;
}

.review-aggregate .bluebar .normal {
    color: $white;
}

.review-aggregate .bluebar .normal .votes {
    font-weight: bold;
}

.reviewbox {
    border: 1px solid $gray-lighter;
    margin-top: 15px;
    padding: 15px;
    position: relative;
}

.reviewbox .blue {
    color: $color-primary;
}

.reviewbox .rating {
    height: 70px;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 70px;
}

.reviewbox .rating .date {
    color: $gray-lighter;
    font-size: 11px;
    text-align: right;
}

.reviewbox .rating .score {
    color: $font-color;
    font-size: 40px;
    font-weight: bold;
    text-align: right;
}

.menu_overlay {
    background: $shadow-black-3;
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
}

.shops_menu_overlay {
    background: $shadow-black-3;
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;
}

.topmenu {
    background-color: $color-primary;
    box-shadow: 0 1px 2px $shadow-black-1;
    position: relative;
    width: 100%;
    z-index: 2;
}

#topmenu {
    height: 40px;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
        max-width: none !important;
    }
}

#topmenu ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0 -10px;
    padding: 0;
}

#topmenu ul ul {
    border: 0;
    box-shadow: none;
    display: table;
    left: 0;
    margin: 0;
}

#topmenu > ul > li {
    display: inline-flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0 10px;
    z-index: 200;
}

#topmenu ul li li {
    display: block;
    float: none;
}

#topmenu ul li li:not(:first-child).category_header {
    margin-top: 22px;
}

#topmenu ul li > a {
    color: $white;
    display: block;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-align: center;
    text-decoration: none;

    @media (max-width: 1095px) {
        font-size: 13px;
    }
}

#topmenu ul li:last-child a {
    background: none;
}

#topmenu > ul > li > a:hover,
#topmenu > ul > li:hover > a,
#topmenu > ul > li.active > a {
    border-bottom: 5px solid $white;
}

#topmenu ul div {
    background-color: $white;
    display: none;
    left: 0;
    padding: 40px 0;
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 10;
}

#topmenu ul div div {
    margin: 0 auto;
    max-width: 1140px;
    min-height: 350px;
    padding: 0 15px;
    position: relative;
    top: 0;
    width: 100%;
}


#topmenu ul div.col4 > ul,
#topmenu ul div.col3 > ul,
#topmenu ul div.col2 > ul {
    padding-right: 20px;
    width: 20%;
}

#topmenu ul div.col4 ul:last-child,
#topmenu ul div.col3 ul:last-child,
#topmenu ul div.col2 ul:last-child {
    border: 0;
}

#topmenu ul div.col1 {
    min-width: 100%;
}

#topmenu ul div.col1 ul {
    padding: 0;
    width: auto;
}

#topmenu ul div ul {
    background-color: $white;
}

#topmenu ul div.col4 ul,
#topmenu ul div.col3 ul,
#topmenu ul div.col2 ul {
    float: left;
}

#topmenu.open ul li:hover div {
    display: block;
}

#topmenu ul li ul li a {
    background: none;
    color: $font-color;
    display: block;
    font-size: 14px;
    font-weight: 300;
    height: inherit;
    line-height: 20px;
    margin: 0;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
}

#topmenu ul li ul li a:hover {
    color: $color-primary;
    text-decoration: underline;
}

#topmenu ul li li.category_header,
#topmenu ul li li.category_header a {
    font-weight: 700;
    text-align: left;
}

#topmenu a.shops_menu_banner {
    color: $font-color;
    max-width: 240px;
    position: absolute;
    right: 0;
    text-align: left;
    text-decoration: none;
    top: 0;
}

#topmenu a:hover.shops_menu_banner {
    color: $color-primary-light;
    text-decoration: underline;
}

#topmenu a.shops_menu_banner img {
    max-width: 100%;
}

#topmenu a.shops_menu_banner title {
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin: 10px 0 0;
}

ul.bottommenu {
    float: left;
    list-style: none;
    margin: 0 20px 0 0;
    width: 270px;
}

ul.bottommenu:last-child {
    margin: 0;
}

ul.bottommenu li {
    color: $white;
    font-size: 18px;
    font-weight: bold;
}

ul.bottommenu li a,
ul.bottommenu li p,
ul.bottommenu_right li a {
    color: $white;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
}

ul.bottommenu li a:hover,
ul.bottommenu_right li a:hover {
    color: $gray-dark;
}

ul.bottommenu_right li a:hover {
    color: $gray-lighter;
}

#topmenu ul li div ul li.sac_menutitle > a {
    font-weight: 700;
    margin: 20px 0 0;
    white-space: nowrap;
}

#topmenu ul li div ul li.sac_menutitle:first-child > a {
    margin: 0;
}

#topmenu ul li div ul li a.allesvan {
    color: $color-primary-light;
    font-weight: bold;
    padding-top: 10px;
}

#topmenu ul li div ul li a.allesvan::after {
    content: ' >';
}

#topmenu ul li div ul li.sac_menutitle ul.rubriekitems {
    float: none;
    margin: 0;
}

#topmenu ul li div ul:last-child li.sac_menutitle ul.rubriekitems li {
    border-right: 0;
}

#breadcrumb {
    font-size: 14px;
    font-weight: bold;
}

#breadcrumb span {
    background: url('/img/icons/arrow-right-grey.svg') no-repeat center center;
    background-size: 5px;
    display: inline-block;
    padding: 0 8px;
    text-indent: -10000px;
    width: 5px;
}

#breadcrumb a {
    font-weight: normal;
    text-decoration: none;
}

#breadcrumb a:hover {
    border-bottom: 1px solid $color-primary;
    color: $color-primary;
}

#breadcrumbs {
    font-size: 14px;
}

.breadcrumb {
    background-color: transparent;

    a {
        text-decoration: none;
    }

    .breadcrumb-item {
        @include media-breakpoint-down(md) {
            a {
                &:hover {
                    text-decoration: underline;
                }
            }

            &:not(:nth-last-child(2)) {
                display: none;
            }

            &:nth-last-child(2) {
                padding-left: 0;
            }

            &::before {
                content: url('/img/icons/breadcrumb-divider-foris-font.svg');
                display: inline-block;
                padding-left: .35rem;
                padding-right: 0;
                transform: rotate(180deg);
            }
        }

        &:last-child {
            font-weight: bold;
        }
    }
}

.page-pdp {
    .breadcrumb {
        .breadcrumb-item {
            @include media-breakpoint-up(lg) {
                &:last-child {
                    display: none;
                }
            }
        }
    }
}

ol.breadcrumb {
    margin: 0;
    padding: 0;
}

.publitas_editions {
    margin-top: 40px;
}

.publitas_editions a {
    border: 2px solid $white;
    display: block;
    float: left;
    font-size: 13px;
    line-height: 24px;
    margin: 0 20px 0 0;
    text-align: center;
    text-decoration: none;
}

.publitas_editions a:hover {
    border: 2px solid $gray-lighter;
}

.nbform input {
    margin-bottom: 10px;
}

.attributen ul {
    list-style: none;
    margin: 0;
}

.attributen.mood_menu ul {
    margin-bottom: 20px;
}

.attributen ul ul {
    display: block;
    margin: 0;
}

.attributen ul li {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: .75rem;
    padding-bottom: .5rem;

    @include media-breakpoint-up(lg) {
        margin: 0 0 30px;
        padding: 0 0 30px;
    }
}

.attributen ul li:last-child {
    border-bottom: 0;
    margin: 0;
    padding: 0;
}

.attributen > ul > li > div {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    user-select: none;
}

.attributen ul li a {
    line-height: 22px;
    margin: 0 0 5px;
    padding: 0 0 5px;
    text-decoration: none;
}

.attributen ul li a#reset_attr {
    display: block;
    font-style: italic;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-align: right;
}

.attributen ul li ul li a {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

@include media-breakpoint-down(md) {
    .attributen.menuleft {
        li > a {
            display: block;
            margin-bottom: 10px;
        }
    }
}

.attributen ul li ul li a:hover {
    color: $color-primary-light;
    text-decoration: underline;
}

.attributen.category ul li ul li a {
    display: inline-block;
    padding: 0;
}

.attributen.category ul li ul li a:hover {
    color: $color-primary;
    text-decoration: underline;
}

.attributen ul li ul li {
    border: 0;
    line-height: 28px;
    margin: 0;
    padding: 0;
    position: relative;

    @include media-breakpoint-up(lg) {
        line-height: 22px;
    }
}

.attributen ul li ul li span.num_results {
    color: $font-color-grey;
}

.attributen.menuleft ul li ul li {
    padding: 0;
}

.attributen ul li ul li.hide {
    display: none;
}

.attributen ul li ul li.checked a {
    font-weight: bold;
}

.attributen ul li div.arrow {
    background: url('/img/icons/arrows-up-down.svg') no-repeat right bottom 6px;
    background-size: 12px;
    cursor: pointer;
    margin-bottom: 6px;
}

.attributen ul li div.arrow.no_bg {
    background-image: none;
    cursor: text;
}

.attributen ul li.toggle_ml {
    color: $color-secondary;
    cursor: pointer;
}

.attributen ul li.toggle_ml em:hover {
    text-decoration: underline;
}

.attributen label {
    cursor: pointer;
    display: block;
    float: none;
    width: 100%;
}

.attributen label:hover span.attr_name,
.attributen a:hover span.attr_name {
    text-decoration: underline;
}

.attributen li.color_group label {
    margin-bottom: 5px;
    padding-left: 30px;
}

.attributen li.color_group label::before {
    height: 20px;
    margin-top: -10px;
    width: 20px;
}

.attributen .search_brand {
    background: url('/img/icons/search-black.svg') no-repeat right 5px center;
    background-size: 18px;
    margin-bottom: 10px;
    width: 100%;
}

.attributen .ui-widget-content {
    background-color: $gray-lighter;
    border: 1px solid $gray-light;
    color: $near-black;
}

.attributen .ui-widget-content a {
    color: $near-black;
}

.attributen .ui-widget-header {
    border: 1px solid $gray-light;
    color: $near-black;
    font-weight: normal;
}

.attributen .ui-widget-header a {
    color: $near-black;
    text-decoration: none;
}

.attributen .ui-slider-horizontal {
    border: 0;
    margin: 18px 8px 0;
}

.attributen .ui-slider-horizontal .ui-slider-handle {
    background-color: $color-secondary;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    height: 16px;
    position: absolute;
    width: 16px;
    z-index: 2;
}

.attributen .ui-slider-horizontal .ui-slider-handle:nth-of-type(1) {
    margin: 2px 0 0 -8px;
}

.attributen .ui-slider-horizontal .ui-slider-handle:nth-of-type(2) {
    margin: 2px 0 0 -8px;
}

.attributen .ui-slider-horizontal .ui-slider-range {
    background-color: $ui-line-divider-grey;
    border: 0;
    display: block;
    font-size: 11px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
}

.attributen #min,
.attributen #max {
    font-weight: normal;
}

.ui-tooltip {
    border: 2px solid $gray-dark;
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    white-space: pre-line;
}

.ui-tooltip-color {
    background-color: $gray-mid;
    border: 1px solid $white;
    border-radius: 0;
    box-shadow: none;
    color: $white;
    font-size: 11px;
    min-width: 40px;
    padding: 5px;
    text-align: center;
}

.ui-tooltip-color .ui-tooltip-content {
    min-width: 40px;
    padding: 0;
    position: relative;
}

.ui-tooltip-color .ui-tooltip-content::after {
    border-color: $gray-mid transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    bottom: -10px;
    content: '';
    display: block;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    width: 0;
}

.ui-tooltip-delivery {
    background-color: $white;
    border: 1px solid $gray-dark;
    border-radius: 5px;
    box-shadow: none;
    font-size: 14px;
    line-height: 20px;
    max-width: 300px;
    padding: 5px;
    text-align: center;
    white-space: pre-line;
    z-index: 100;
}

.ui-tooltip-delivery::after {
    border: 5px solid transparent;
    border-bottom-color: $black;
    bottom: 100%;
    content: '';
    position: absolute;
}

.pdp-tooltip {
    background-color: $white;
    border: 1px solid $gray-dark;
    border-radius: 5px;
    box-shadow: none;
    font-size: 14px;
    line-height: 20px;
    max-width: 300px;
    padding: 5px;
    text-align: center;
    white-space: pre-line;
    z-index: 100;
}

.ui-helper-hidden-accessible {
    display: none;
}

ul.knoppen {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.knoppen li {
    float: left;
}

ul.knoppen a {
    background-color: $blue-light;
    border: 1px solid $color-secondary;
    display: block;
    margin: 0 10px 10px 0;
    padding: 5px;
    text-decoration: none;
}

ul.knoppen a:hover {
    background-color: $white;
}

ul.list-check {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.list-check li {
    background: url('/img/icons/voorraad.svg') no-repeat left 4px;
    background-size: 16px;
    display: block;
    line-height: 24px;
    padding-left: 24px;
}

div.kiyoh-footer p {
    color: $white;
}

div.kiyoh-footer span,
div.kiyoh-footer span.item {
    display: inline-block;
    padding: 0;
}

div.kiyoh-footer .star-rating {
    float: left;
    left: 0;
    margin-top: 5px;
    position: inherit;
    top: 0;
}

#footer_logo {
    display: block;
    width: 100%;
}

a.delivery_tip {
    background: url('/img/icons/info-foris.svg') no-repeat center center;
    background-size: 16px;
    display: block;
    float: right;
    height: 16px;
    width: 16px;
}

.az_col {
    float: left;
    list-style: none;
    margin: 0 20px 0 0;
    padding: 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
        columns: 2;
    }

    @include media-breakpoint-up(md) {
        columns: 3;
    }

    @include media-breakpoint-up(lg) {
        columns: 4;
    }

    @include media-breakpoint-up(xl) {
        columns: 5;
    }

    @include media-breakpoint-down(md) {
        margin-top: 10px !important;

        li {
            margin-bottom: 10px;
        }
    }
}

.az_col:last-child {
    margin: 0;
}

.az_col li {
    line-height: 20px;
    margin-left: 0;
    padding-left: 0;
}

.az_row {
    border-top: 1px solid $gray-lighter;
    margin-top: 20px;
    padding-top: 40px;
}

.az_left {
    float: left;
    width: 100px;
}

.az_right {
    float: left;
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: calc(100% - 100px);
    }
}

.az_right a {
    text-decoration: none;
}

.az_right a:hover {
    color: $color-primary;
    text-decoration: underline;
}

.az_letter {
    color: $color-secondary;
    font-size: 24px;

    &:hover {
        color: $color-secondary;
    }
}

.sfeerpage * {
    border-radius: 8px;
    width: 100%;
}

.sfeerpage .subs img {
    border-radius: 8px 8px 0 0;
}

.sfeerpage p {
    margin-bottom: 18px;
}

.sfeerpage h2 {
    color: $gray-dark;
    font-weight: 700;
}

.sfeerpage .block a {
    display: block;
    margin-bottom: 20px;
    position: relative;
    text-decoration: none;
}


.sfeerpage .block a.top div {
    background: $white-semitransparent-1;
    border-radius: 0;
    bottom: 0;
    font-size: 14px;
    left: 0;
    line-height: 18px;
    padding: 10px;
    position: absolute;
    text-align: center;
    z-index: 1;
}

.sfeerpage .block a:hover.main div {
    background: $white-semitransparent-2;
}

.sfeerpage .block a.subs {
    border: 1px solid $gray-lighter;
    border-radius: 10px;
    overflow: hidden;
}

.sfeerpage .block a:hover.subs {
    background-color: $gray-lighter;
}

.sfeerpage .block a.subs div {
    display: block;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
    z-index: 1;
}

.sfeerpage .block div::first-line {
    font-size: 16px;
    font-weight: 700;
}

.sfeerpage div.txtbox::after {
    content: ' >';
}

.gift_box {
    background: $color-secondary no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    display: block;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
    overflow: hidden;
    padding: 190px 30px 30px;
    position: relative;

    @media (min-width: 575px) {
        padding: 30px;
    }


    table.filters {
        margin-bottom: 0;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            width: 35%;
        }
    }
}

.account_right {
    @include media-breakpoint-up(lg) {
        border-left: 1px solid $gray-lighter;
    }
}

.account_right p {
    line-height: 30px;
}

.account_right ul {
    list-style: none;
    margin: 0;
}

.account_right ul li {
    background: url('/img/icons/voorraad.svg') no-repeat left center;
    background-size: 16px;
    line-height: 30px;
    padding: 0 0 0 30px;
}

.add-badge {
    background-color: $gray-dark;
    border-radius: 50%;
    color: $white;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    margin: 2px 0;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    width: 30px;
}

.add-badge:hover,
.add-badge:active,
.add-badge:focus,
.add-badge:visited {
    color: $white;
    text-decoration: none;
}

div.return_procedure label {
    display: block;
    float: none;
    width: 100%;
}

table.border_tb {
    border-bottom: 1px solid $gray-lighter;
    border-top: 1px solid $gray-lighter;
}

table.border_tb td {
    padding: 10px 0;
}

table.border_tb tr:not(:last-child) td {
    border-bottom: 1px solid $gray-lighter;
}

table.grey_bg tr td {
    background-color: $gray-lighter;
    line-height: 15px;
    padding: 10px;
}

table.grey_bg tr:not(:first-child) td {
    border-top: 10px solid $white;
}

table.grey_bg td.pm_svg {
    background: $gray-lighter url('/img/betaalmethode-sprite.svg') no-repeat center top;
    background-size: 40px 595px;
}

table.split_border tr td {
    border: 0;
    padding: 5px;
}

table.split_border tr:not(:first-child) td {
    border-top: 1px solid $white;
}

.extra_box {
    display: none;
    padding: 10px 0 0 20px;
}

.extra_box a {
    border-bottom: 1px dashed $gray-dark;
    text-decoration: none;
}

div.cookiebar {
    background-color: $black;
    bottom: 10px;
    color: $white;
    font-size: 12px;
    left: 50%;
    line-height: 18px;
    margin-left: -275px;
    opacity: .8;
    padding: 10px;
    position: fixed;
    width: 550px;
    z-index: 5;
}

div.cookiebar a {
    color: $white;
}

div.cookiebar .close {
    background: url('/img/icons/cross-white.svg') no-repeat right center;
    background-size: 10px;
    float: right;
    font-style: italic;
    padding-right: 20px;
    text-decoration: none;
}

#cv_enq_dicht {
    background-size: contain !important;
    z-index: 1;
}


@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select.aantal_pd {
        height: 20px;
        line-height: 14px;
        padding: 5px 15px 5px 5px;
        position: relative;
    }
}

@media print {
    .header_top_box,
    .account_cart,
    .searchbox,
    .shops,
    .topmenu,
    .menu,
    .footer_wrapper,
    #robin_tab_container {
        display: none !important;
    }

    .cbody {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    #breadcrumb {
        position: inherit;
    }
}

.capayable_info,
.klarna_info {
    display: none;
}

.capayable_kvknummer {
    display: none;
}

.avg-cookiebar {
    bottom: 0 !important;
    top: auto !important;
}

.avg-cookiebar__inner {
    max-width: 1180px !important;
    padding: 20px !important;
}

.avg-cookiebar__title {
    line-height: 24px !important;
}

.avg-cookiebar__content {
    max-width: inherit !important;
    padding: 0 !important;
    width: 100% !important;

    p {
        line-height: 20px !important;
    }
}

.avg-cookiebar__tab {
    padding: 20px 0 0 !important;
    vertical-align: text-bottom !important;
}

.avg-cookiebar__tab .button {
    float: right !important;
    margin: 10px 0 0 10px !important;
}


.g-recaptcha {
    margin-bottom: 1em;
    margin-top: 1em;
}

.login-for-price-container {
    color: $font-color;
    font-size: 20px;
    font-weight: bold;
}

.login-for-price {
    color: $font-color;
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin: .5em 0;
}

.vat-container {
    font-size: .7em;
    font-weight: normal;
}

.inc_vat_price {
    color: $gray-dark;
    font-size: .7em;
    padding-top: .5em;
}


.small-heading {
    font-size: 18px;
}

.vat-total > #vat_low,
.vat-total > #vat_high,
.vat-total > .currency_container,
#total_inc_vat {
    float: right;
}

#fullpagebox .tab-container .tabs {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 30%;
}

#fullpagebox .tab-container .tabs-list {
    list-style-type: none;
    margin-left: 0;
}

#fullpagebox .tab-container .tabs-list > li > a {
    text-decoration: none;
}

#fullpagebox .tab-container .tabs-list > li > a.checked {
    font-weight: bold;
}

#fullpagebox .tab-container .tabs-list > li > a:hover {
    color: $color-primary;
    text-decoration: underline;
}

#fullpagebox .tab-container .tab-panes {
    box-sizing: border-box;
    display: inline-block;
    margin-top: -31px;
    width: 69%;
}

#fullpagebox.hidden {
    z-index: 0 !important;
}

.garbage {
    display: none;
    visibility: hidden;
}

.award {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 112px;
    left: 0;
    position: absolute;
    top: 0;
    width: 112px;
    z-index: 1;

    @include media-breakpoint-up(md) {
        height: 150px;
        width: 150px;
    }

    &-small {
        height: 48px;
        left: 0;
        position: absolute;
        top: 0;
        width: 48px;

        @include media-breakpoint-up(lg) {
            height: 90px;
            width: 90px;
        }
    }

    &.fietstest-pddp20 {
        background-image: url('/img/popal_daily_dutch_prestige_keurmerk.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.flex-container {
    display: flex;
    font-size: 14px;
}

.flex-container .hb-left {
    flex-grow: 1;
}

.flex-container .hb-middle {
    flex-shrink: 0;
    margin: 0 15px;
    width: 570px;
}

.flex-container .hb-right {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 315px;
}

.flex-container .block {
    background-color: $white;
    border: 1px solid $gray-lighter;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
    padding: 15px 20px;
    text-decoration: none;
}

.flex-container .block:last-child {
    margin-bottom: 0;
}

.usps_footer {
    div.row {
        background-color: $gray-lighter;
        padding: 20px;
    }
}

a.arrow-blue {
    background-color: $white;
    color: $color-primary;
    padding-left: 30px !important;
    position: relative;

    &:hover {
        color: $color-primary-light !important;
    }
}

a.arrow-blue::before {
    border-color: transparent transparent transparent $color-secondary;
    border-style: solid;
    border-width: 5px 0 5px 8px;
    content: '';
    display: block;
    left: 10px;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
}

a.arrow-red {
    background-color: $white;
    padding-left: 30px !important;
    position: relative;
}

a.arrow-red::before {
    border-color: transparent transparent transparent $red-label;
    border-style: solid;
    border-width: 5px 0 5px 8px;
    content: '';
    display: block;
    left: 10px;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
}




// SEO category
.seo-nav {
    font-size: 14px;
    line-height: initial;
    margin: 0 0 30px;
}

.seo-nav a {
    margin-right: 20px;
    text-decoration: none;
}

.seo-nav a:last-child {
    margin-right: 0;
}

.seo-nav a.active {
    border-bottom: 2px solid $color-primary;
}

.seo-page {
    font-family: $font-family;
}

@include media-breakpoint-down(md) {
    .slider_overlay {
        background: linear-gradient(to right, $white 0%, $white-transparent 5%, $white-transparent 95%, $white 100%);
        height: 100%;
        max-height: 260px;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 1;

        @include media-breakpoint-down(xs) {
            max-height: 210px;
        }
    }
}

.seo-article {
    margin-bottom: 3rem;
}

.seo-article a {
    text-decoration: none;
}

.seo-article .thumbnail {
    background: $gray-lighter center / cover no-repeat;
    height: 200px;
    margin-bottom: 1rem;
    width: 100%;
}

.seo-article h3 {
    font-size: 16px;
}

.seo-article a:hover h3 {
    text-decoration: underline;
}

.seo-article time {
    color: $gray-light;
    display: inline-block;
    font-size: 13px;
    margin: .25rem 0 .5rem;
    text-decoration: none !important;
}

#accordion article,
.accordion-subcat {
    border-top: 1px solid $gray-lighter;
    padding: .75rem 0;
}

#accordion article:last-child,
.accordion-subcat:last-child {
    border-bottom: 1px solid $gray-lighter;
}

#accordion .accordion-header {
    background: none;
    cursor: pointer;
}

.accordion-subcat > a {
    color: $color-primary-light;
    display: flex;
    font-weight: 300;
    text-decoration: none;

    &:hover {
        color: $color-primary;
    }
}

.accordion-subcat .accordion-header {
    color: $color-primary-light;
    flex-grow: 1;
    font-weight: 300;
    text-decoration: none;

    &:hover {
        color: $color-primary;
    }
}

.icon-down,
.icon-up {
    background: url('/img/icons/arrow-down-darkblue.svg') center / 100% no-repeat;
    display: block;
    float: right;
    height: 24px;
    margin-right: 10px;
    width: 14px;
}

.icon-up {
    transform: rotate(180deg);
}

#accordion .ui-widget-content {
    border: 0;
    margin-top: .75rem;
}

// SEO page
.author,
.post-date {
    color: $gray-lighter;
    font-size: 12px;
    margin-top: 5px;
}

.author::before,
.post-date::before {
    background-size: cover;
    content: ' ';
    display: inline-block;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    width: 14px;
}

.author::before {
    background-image: url('/img/icons/header/account.svg');
}

.post-date::before {
    background-image: url('/img/icons/clock.svg');
}

.product-highlight-container {
    margin-bottom: 15px;
    margin-top: 15px;
}

.product-highlight-container .product-highlight:last-child {
    border-bottom: 1px solid $gray-lighter;
}

.product-highlight a {
    text-decoration: none !important;
}

.product-highlight > * {
    margin-bottom: 5px;
    margin-top: 5px;
}

.product-highlight .product-image {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    min-width: 100px;
    width: 100px;
}

.product-highlight .product-image a {
    display: block;
}

.product-highlight .product-image img {
    max-height: 100px;
    max-width: 100px;
}

.product-highlight {
    align-items: center;
    border-top: 1px solid $gray-lighter;
    display: flex;

    .product-info-container {
        display: flex;
        flex-grow: 1;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            margin-left: 20px;
        }

        .product-info {
            flex-grow: 1;

            @include media-breakpoint-up(sm) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        .actionbuttons {
            max-width: 150px;
            text-align: center;

            @include media-breakpoint-down(xs) {
                margin-top: 10px;
            }
        }
    }
}

.product-highlight .product-price {
    margin-top: 10px;
}

.product-highlight .adviesprijs {
    color: $ui-line-divider-grey;
    font-size: 12px;
    font-weight: normal;
    text-decoration: line-through;
}

.product-highlight .product-info .prijs {
    color: $font-color;
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
}

.product-highlight .actionbuttons a {
    display: block;
    white-space: nowrap;
}

.page-item.active .page-link {
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
}

// PDP Redesign
.show-more,
.toggleArrow {
    color: $color-primary;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    position: relative;
    text-decoration: none;
}

.toggle-container {
    border-radius: 3px;
    margin-left: 15px;
    margin-top: 5px;
    padding: 5px 7px 5px 5px;
    cursor: pointer;
    overflow: hidden;
    max-height: 500px;
    width: auto;

    &:hover {
        .toggleArrow {
            text-decoration: underline;
        }
    }
}

@include media-breakpoint-up(lg) {
    .toggle-container {
        border: 1px solid $color-primary;
    }
}

.expanded {
    max-height: fit-content !important;
    transition: max-height 0.5s ease;
}

.show-more.arrow-right {
    margin-left: 15px;
}

.show-more.arrow-right::before {
    content: '\203A';
    display: inline-block;
    font-size: 30px;
    height: 30px;
    left: -15px;
    line-height: 30px;
    margin-right: 5px;
    position: absolute;
    top: -8px;
}

.show-more.arrow-down,
.toggleArrow {
    margin-left: 21px;

    &.margin-fix {
        margin-left: 20px;
    }
}

.show-more.arrow-down::before,
.toggleArrow.down::before {
    content: '\203A';
    display: inline-block;
    font-size: 30px;
    height: 30px;
    left: -15px;
    line-height: 30px;
    margin-right: 5px;
    position: absolute;
    top: -5px;
    transform: rotate(90deg);
}

.toggleArrow.up::before {
    content: '\203A';
    display: inline-block;
    font-size: 30px;
    height: 30px;
    left: -20px;
    line-height: 30px;
    margin-right: 5px;
    position: absolute;
    top: -5px;
    transform: rotate(-90deg);
}

.d-none {
    display: none;
}

.d-none-i {
    display: none !important;
}

.mt-15 {
    margin-top: 15px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

@include media-breakpoint-up(md) {
    .font-size-md-18 {
        font-size: 18px;
    }

    .font-size-md-22 {
        font-size: 22px;
    }

    .font-size-md-24 {
        font-size: 24px;
    }
}

@include media-breakpoint-up(lg) {
    .font-size-lg-14 {
        font-size: 14px;
    }

    .font-weight-lg-normal {
        font-weight: normal !important;
    }
}

.d-block {
    display: block;
}

.w-100 {
    width: 100%;
}

.text-center {
    text-align: center;
}

.color-white {
    color: $white;
}

.color-red {
    color: $red-primary;
}

.bg-blue {
    background-color: $color-primary;
}

.bg-red-a-05 {
    background-color: $red-transparent;
}

.border-blue {
    border-color: $color-primary;
}

.border-red {
    border-color: $red-primary;
}

.border-top-dedede {
    border-top: 1px solid $ui-line-divider-grey;
}

.border-bottom-dedede {
    border-bottom: 1px solid $ui-line-divider-grey;
}

.img-fluid {
    height: auto;
    max-width: 100%;
}

.category-banners .banner-inner {
    border: 1px solid $gray-lighter;
    font-size: 14px;
    margin-bottom: 1rem;
    overflow: hidden;
}

.category-banners a.banner-link {
    color: $font-color;
    display: block;
    text-decoration: none;
}

.category-banners a.banner-link:hover {
    color: $gray-dark;
    text-decoration: underline;
}

.category-banners .banner-inner .banner-image {
    background-position: center;
    background-size: cover;
    height: 140px;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-down(md) {
        height: 200px;
    }

    @include media-breakpoint-down(sm) {
        height: 170px;
    }
}

.category-banners .banner-inner:hover .banner-image {
    transform: scale(1.08);
}

.category-banners .banner-inner .banner-title {
    background-color: $white;
    padding: 10px;
    position: relative;
    z-index: 2;
}

.category-banners .banner-inner .banner-image + .banner-title {
    border-top: 1px solid $gray-lighter;
}

// RESPONSIVE
.list-style-none {
    list-style: none;
    margin-left: 0;
}

.notification-badge {
    background-clip: padding-box;
    background-color: $color-secondary;
    border: 3px solid $white;
    border-radius: 11px;
    box-sizing: border-box;
    color: $white;
    font-size: 10px;
    height: 22px;
    left: 50%;
    line-height: 16px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-decoration: none;
    text-decoration-color: $white;
    top: -4px;
    width: 22px;
}

@include media-breakpoint-up(sm) {
    .has-notification-badge {
        padding-right: 0;
    }
}

// HEADER
.header_top_box {
    background-color: $color-secondary-light;
}

.sticky_box.header {
    background-color: $white;
}

.language_currency {
    display: none;
}

.language_currency {
    margin: 0 !important;
}

.language_currency a {
    padding: 0 !important;
    padding-right: 5px !important;
}

.header_usp {
    list-style: none;
    margin: 0;
    max-height: 40px;
    overflow: hidden;
    white-space: nowrap;
}

.header_usp li {
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 6px;
    text-align: center;
    width: 100%;
}

.header_usp li span {
    background-image: url('/img/icons/check_green_foris.svg');
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: 14px;
    padding-left: 17px;
}

.header_usp li a {
    color: $font-color;
    text-decoration: none;
}

.logo {
    img {
        max-width: 100%;

        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }
    }
}

.tom_shop_logo {
    img {
        max-width: 80%;
    }
}

.account_cart {
    @include media-breakpoint-down(md) {
        margin-right: 12px;
    }
}

.account_cart a {
    text-decoration: none;
}

.account_cart ul.cart_topmenu {
    background-color: $white;
    display: none;
    filter: drop-shadow(0 0 5px $shadow-black-1);
    font-weight: normal;
    line-height: 24px;
    list-style: none;
    margin: 0;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 65px;
    width: 200px;
    z-index: 2;

    &::after {
        border-color: $white $white transparent transparent;
        border-style: solid;
        border-width: 10px;
        content: '';
        height: 0;
        position: absolute;
        right: 28px;
        top: 0;
        transform: rotate(-45deg);
        transform-origin: 0 0;
        width: 0;
    }

    li {
        float: none;
        margin: 0;
        padding: 0;
        text-align: left;

        a {
            display: inline-block;
            font-size: 13px;
            padding: 0;
            text-align: left;
            text-decoration: none;
            width: 90%;

            &:hover {
                color: $color-primary-light;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .logo img {
        max-height: inherit;
    }
}

@include media-breakpoint-up(md) {
    .overflow-md-inherit {
        overflow: inherit !important;
    }

    .header_top_box {
        background-color: $color-secondary-light;
    }

    .header_usp {
        white-space: normal;
    }

    .header_usp li {
        width: auto;
    }

    .language_currency {
        display: inherit;
    }
}

@include media-breakpoint-up(lg) {
    .overflow-lg-inherit {
        overflow: inherit !important;
    }

    .searchform {
        max-width: 400px;
    }
}

// Fullscreen modal
body.modal-open {
    overflow: visible;
}

.modal-fs {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;

    .modal-dialog {
        height: 100%;
        left: -85%;
        margin: 0;
        padding: 0;
        position: fixed;
        transform: inherit !important;
        transition: opacity .2s linear, left .2s ease-out !important;
        width: 85%;
    }

    .modal-content {
        border: 0 !important;
        border-radius: 0;
        bottom: 0;
        box-shadow: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .modal-header {
        align-items: center;
        background-color: $color-secondary;
        border: 0;
        border-radius: 0;
        color: $white;
        height: 50px;
        left: 0;
        padding: 0 15px;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: space-between; // Aligns items to each end of the container

        h4 {
            margin: 0;
            color: $white; // In case you want to override the color
            font-size: 1.25em;
            font-weight: 600;
        }

        .close {
            color: $black;
            opacity: 1;
            background: none;
            border: none;
            font-size: 2em;
        }
    }


    .modal-body {
        bottom: 0;
        font-weight: 300;
        position: absolute;
        top: 50px;
        width: 100%;
    }

    .modal-footer {
        border: 0;
        bottom: 0;
        height: 60px;
        left: 0;
        padding: 0 10px;
        position: absolute;
        right: 0;
    }
}

.modal-open .modal {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;

    .modal-body {
        overflow-y: scroll;
    }
}

.modal.left.fade.show .modal-dialog {
    left: 0;
}


.banners-container {
    border-bottom: 1px solid $gray-lighter;
    border-top: 1px solid $gray-lighter;
    font-size: 14px;

    @include media-breakpoint-up(lg) {
        border-bottom: 0;
        border-top: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 2px;
        width: 250px;
    }

    @include media-breakpoint-up(xl) {
        width: 315px;
    }
}

a.link-dark,
.link-dark a {
    color: $font-color;
}

a.link-blue,
.link-blue a {
    color: $color-primary-light;
}

.link-blue > p {
    color: $font-color;
}

.color-white-all,
.color-white-all p,
.color-white-all a {
    color: $white;
}

.scroll-box {
    margin-left: -15px;
    margin-right: -15px;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .scroll-box-inner {
        display: inline-flex;
        padding-left: 15px;
        padding-right: 15px;
        white-space: nowrap;
    }
}

.btn {
    border-radius: 74px;
}

.btn-blue {
    background-color: $button-color-primary;
    border-radius: 74px;
    box-shadow: 0 2px 2px 0 $shadow-black-2;
    color: $font-color;
    height: 43px;
    transition: all .1s;

    &:hover {
        background-color: $button-color-primary-hover;
        color: $font-color;
    }

    &.search-button {
        box-shadow: none;
    }
}

.btn-filter {
    background-color: $white;
    border-color: $color-primary;
    border-radius: 91px;
    box-shadow: 0 1px 2px $shadow-black-1;
    color: $color-primary;
    height: 43px;
}

.cart_usp_box {
    margin: auto -15px;

    @include media-breakpoint-up(lg) {
        margin: auto;
    }

    #add_cart {
        border-bottom: 1px solid $ui-line-divider-grey;
        border-radius: 3px;

        @include media-breakpoint-up(lg) {
            border: 1px solid $ui-line-divider-grey;
        }
    }

    .add_cart_container {
        background-color: $white;
        display: flex;
        width: 100%;

        @include media-breakpoint-down(md) {
            border-top: 1px solid $gray-lighter;
            bottom: 0;
            box-shadow: 0 8px 11px 6px $gray-mid-light;
            justify-content: center;
            left: 0;
            padding: 15px;
            position: fixed;
            z-index: 3;

            .fullsubmit {
                width: 100% !important;
            }
        }
    }
}

.border-top-light-gray {
    border-top: 1px solid $gray-lighter;
}

.border-bottom-none {
    border-bottom: 0;
}

.border-bottom-light-gray {
    border-bottom: 1px solid $gray-lighter;
}

@include media-breakpoint-up(lg) {
    .disable-modal-lg {
        display: block !important;
        opacity: 1 !important;
        overflow: auto;
        position: relative !important;
        z-index: 1;

        .modal-dialog {
            left: 0;
            max-width: inherit;
            position: relative;
            width: 100%;

            .modal-content {
                position: relative;

                .modal-header {
                    display: none;
                }

                .modal-body {
                    padding: 0;
                    position: relative;
                    top: 0;
                }
            }
        }
    }

    .overflow-lg-hidden {
        overflow: hidden !important;
    }

    .border-top-lg-none {
        border-top: 0;
    }

    .border-top-lg-light-gray {
        border-top: 1px solid $gray-lighter;
    }

    .border-bottom-lg-none {
        border-bottom: 0;
    }

    .border-bottom-lg-light-gray {
        border-bottom: 1px solid $gray-lighter;
    }
}

@include media-breakpoint-up(xl) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1170px;
    }
}

.form-group {
    label,
    .label {
        margin-bottom: .5rem;
    }
}

p.paragraph-m-0,
.paragraph-m-0 p {
    margin-bottom: 0 !important;
}

.underline-none a:not(:hover),
a.underline-none {
    text-decoration: none;
}

.underline-hover-none a:hover,
a.underline-hover-none:hover {
    text-decoration: none;
}

@include media-breakpoint-down(md) {
    .loading-overlay::before {
        background: $black url('/img/loader.gif') center center no-repeat;
        content: '';
        height: 100%;
        opacity: .5;
        pointer-events: fill;
        position: absolute;
        width: 100%;
        z-index: 3;
    }
}

.btn-orange {
    background-color: $orange;
    box-shadow: 0 2px 2px 0 $shadow-black-2;
    color: $font-color;
    display: inline-block;
    text-align: center;

    &:hover {
        background-color: $orange-dark;
        color: $white;
    }
}

@include media-breakpoint-down(md) {
    #aae-prijs {
        margin-bottom: .5rem;
    }
}

.select-ideal {
    width: calc(100% - 50px);
}

.cat-intro {
    input {
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    input:checked + .intro-text {
        -webkit-line-clamp: unset;
    }

    input:checked ~ label {
        display: none;
    }

    label {
        color: $color-primary;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .intro-text {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            -webkit-line-clamp: 1;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.radio-payment-btn label.radio-payment-btn::before {
    margin-left: -25px;
    top: 9px;
}

.radio-payment-btn label.radio-payment-btn::after {
    margin-left: -25px;
    top: 12px;
}

.radio-info input[type=radio]:checked + label::after {
    background-color: $color-secondary;
}

.cart-summary {
    .product-row td {
        border-bottom: 1px solid $gray-lighter;
        line-height: initial !important;
        padding: 3px 0;
    }
}

.search-fullpage-overlay {
    background-color: $white;
    box-sizing: border-box;
    display: none;
    height: 100vh;
    left: 0;
    overflow-x: scroll;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 9999;
}

body.search-overlay {
    .search-fullpage-overlay {
        @include media-breakpoint-up(lg) {
            display: none !important;
        }
    }

    .search-autocomplete-modal {
        border: 0;
        display: block !important;
        left: 0 !important;
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        padding-top: 10px;
        width: 100% !important;
        z-index: 999;

        .ui-menu-item {
            border-top: 1px solid $gray-lighter;
        }

        li div {
            color: $gray-dark;
            padding: 15px 10px;
        }

        li:hover,
        li div.ui-state-active {
            background-color: $gray-lighter;
            cursor: pointer;
        }
    }
}

.search-autocomplete {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.fa-angle-left {
    background: url('/img/icons/arrows-left-right.svg') no-repeat center bottom;
    background-size: 25%;
    display: inline-block;
    height: 1.5em;
    width: 1.5em;

    &.lightBlue {
        background: url('/img/icons/arrows-left-right-blue.svg') no-repeat center bottom;
        background-size: 25%;
        float: left;
        height: 1em;
    }
}

.fa-search {
    background: url('/img/icons/search.svg') no-repeat center;
    background-size: 100%;
    height: 1.5rem;
    width: 100%;
}

#recommendation_box {
    .psum {
        margin-top: 0 !important;
    }

    .accessories_items {
        margin-top: 0 !important;
    }

    .pc_slides {
        margin-top: 0 !important;
    }

    .recommendationsubmit {
        background: $button-color-primary url('/img/icons/winkelwagen-foris.svg') no-repeat right 20px center;
        background-size: 25px;
        border: 0;
        border-radius: 45px;
        box-shadow: 0 2px 2px 0 $shadow-black-2;
        color: $font-color;
        display: inline-block;
        font-size: 18px;
        font-weight: normal;
        height: 60px;
        line-height: 60px;
        margin: 10px 0 0;
        overflow: hidden;
        padding: 0 0 20px 39px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all .1s;
        width: 60px;

        &:hover {
            background-color: $button-color-primary-hover;
        }
    }

    .submitSuccess {
        background: url('https://assets.foris.shop/images/check-white.svg') no-repeat center;
        background-color: $green !important;
    }

    .img {
        height: 90px;
        white-space: nowrap;
        width: 90px;

        @include media-breakpoint-up(sm) {
            height: 120px;
            width: 120px;
        }

        @include media-breakpoint-up(md) {
            height: 120px;
            width: 150px;
        }

        @include media-breakpoint-up(lg) {
            height: 120px;
            width: 100%;
        }

        .img-helper {
            display: inline-block;
            height: 100%;
            margin-left: -4px;
            vertical-align: middle;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            vertical-align: middle;
        }
    }

    .product-text {
        font-size: 14px;
        height: 75px;
    }

    .name {
        font-size: 14px;
        height: 75px;
        line-height: initial;
        min-height: 38px;
        text-align: left;
    }

    .prijs {
        color: $font-color;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
        white-space: nowrap;
    }

    .adviesprijs {
        color: $font-color-grey;
        font-size: 14px;
        font-weight: 300;
        text-decoration: line-through;
    }
}

.plp-submit {
    background: $button-color-primary url('/img/icons/winkelwagen-foris.svg') no-repeat right 20px center;
    background-size: 25px;
    border: 0;
    border-radius: 45px;
    box-shadow: 0 2px 2px 0 $shadow-black-2;
    color: $font-color;
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    height: 60px;
    line-height: 60px;
    margin: 10px 0 0;
    overflow: hidden;
    padding: 0 0 20px 39px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .1s;
    width: 60px;

    &:hover {
        background-color: $button-color-primary-hover;
    }
}

.submitSuccess {
    background: url('https://assets.foris.shop/images/check-white.svg') no-repeat center;
    background-color: $green !important;
}

.img-valign-helper {
    display: inline-block;
    height: 100%;
    margin-left: -4px;
    vertical-align: middle;
}

// Loaders and spinners
.loader-dots,
.loader-dots::before,
.loader-dots::after {
    animation: load7 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    border-radius: 50%;
    height: 2.5em;
    width: 2.5em;
}

.loader-dots {
    animation-delay: -.16s;
    color: $gray-dark;
    font-size: 10px;
    margin: 0 auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0) scale(.25);
}

.loader-dots::before,
.loader-dots::after {
    content: '';
    position: absolute;
    top: 0;
}

.loader-dots::before {
    animation-delay: -.32s;
    left: -3.5em;
}

.loader-dots::after {
    left: 3.5em;
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.mb-par-last-0 p:last-child {
    margin-bottom: 0;
}

.arrow-open-right {
    background: url('/img/icons/arrow-open-right.svg') no-repeat center;
    background-size: 50%;
    display: block;
    float: right;
    height: 1.5em;
    width: 1.5em;
}

.collapse-trigger {
    display: block;
    text-decoration: none !important;

    @include media-breakpoint-down(sm) {
        border-bottom: 1px solid $white;
        margin-bottom: 9px;
        padding-bottom: 9px;

        &::after {
            background-image: url('/img/icons/arrow-right-white.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            float: right;
            height: 10px;
            margin-right: 3px;
            margin-top: 6px;
            transform: rotate(-90deg);
            width: 10px;
        }

        &.collapsed::after {
            transform: rotate(90deg);
        }
    }

    @include media-breakpoint-up(md) {
        &.show-md {
            pointer-events: none;
        }
    }

    + ul {
        li {
            @include media-breakpoint-down(sm) {
                border-bottom: 1px solid $white;
                margin-bottom: .5rem;
                padding-bottom: .5rem;
                padding-left: 15px;
            }

            @include media-breakpoint-up(md) {
                padding: 1px 0;
            }

            a {
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
        }
    }
}


.chat-wrapper {
    display: none;
}

.chat-wrapper i {
    left: -15px;
    position: relative;
    top: 2px;
}

.chat-wrapper .chat-button {
    border-radius: 2rem;
}

.chat-wrapper .chat-status {
    background-color: $green;
    border: 2px solid $white;
    display: inline-block;
    height: 15px;
    left: -13px;
    position: relative;
    top: -15px;
    width: 15px;
}

.chat-wrapper span {
    left: -10px;
    position: relative;
}

.contact-wrapper {
    display: none;
}

.contact-wrapper .contact-button {
    border-radius: 1rem;
}

.btn-dark {
    background-color: $gray-dark;
    border-color: $gray-dark;
    color: $white;
}

.btn-dark:hover {
    background-color: $gray-darkest;
    border-color: $gray-dark;
    color: $white;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 .2rem $gray-semitransparent;
}

.btn-dark.disabled,
.btn-dark:disabled {
    background-color: $gray-dark;
    border-color: $gray-dark;
    color: $white;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    background-color: $gray-darkest;
    border-color: $gray-dark;
    color: $white;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem $gray-semitransparent;
}

.reviews-usp {
    display: inline-flex !important;
}

.reviews-usp-inner {
    display: flex;

    @media (max-width: 1105px) {
        display: flex;
        margin: 0 auto;
    }
}

%baseLabelContainer {
    font-size: 13px;
    font-weight: 300;
    line-height: 14px;
    width: auto;
    column-gap: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    height: fit-content;
    position: relative;
    top: -5px;
}

.label-container {
    @extend %baseLabelContainer;

    @include media-breakpoint-up(sm) {
        top: -30px;
        margin-bottom: -30px;
    }
}

.label-container-swiper-top {
    @extend %baseLabelContainer;
    height: 10px;
    top: -45px;

    @include media-breakpoint-up(sm) {
        top: -27px;
    }
}

.plp-product-description-container {
    position: relative;
    top: 10px;
}

.menu_toggle {
    color: $font-color;

    &:hover {
        color: $color-primary-light;
    }
}

.oms > p {
    color: $font-color;
}

.grey-block {
    background-color: $color-secondary-light;
}

.size_select a.option.selected {
    background-color: $color-secondary-shadow;
    border-color: $color-secondary;
    color: $color-secondary;
}

.light-grey-background {
    background-color: $color-secondary-light;
}

.header-bottom-shadow {
    box-shadow: 0 1px 2px $shadow-black-1 !important;
}

.usp_pdp2 li strong {
    color: $color-secondary !important;
}

.secondary-button {
    background-color: $white;
    border: 1px solid $font-color;
    border-radius: 74px;
    box-shadow: 0 1px 2px $shadow-black-1 !important;
    color: $font-color;
    font-size: 14px;
    padding: 10px 20px;

    &:hover {
        border-color: $color-primary-light;
        color: $color-primary-light;
    }
}

.keuzehulp {
    background-color: $button-color-primary;
    border: 0;
    border-radius: 74px;
    box-shadow: 0 2px 2px 0 $shadow-black-2;
    color: $font-color;
    font-size: inherit;
    padding: .75rem 1.75rem;

    &:hover {
        background-color: $button-color-primary-hover;
        transition: all .1s;
    }
}

.oms > p > img[alt~="positive"] {
    content: url('/cms/img/pluspunt-foris.svg');
}

.header_center {
    margin-top: 0;

    @include media-breakpoint-up(md) {
        margin-top: 10px;
    }
}

.button-coupon {
    background-color: $button-color-primary !important;
    border-radius: 74px;
    color: $font-color;

    &:hover {
        background-color: $button-color-primary-hover;
    }
}

.highlight_text_image {
    background-position: center;
    background-size: cover;
    border: 1px solid $ui-line-divider-grey;
    border-radius: 8px;
    height: 200px;
    width: 100%;
}

.logo-container {
    justify-content: start !important;

    @include media-breakpoint-down(lg) {
        justify-content: center !important;
        padding-left: 0;
    }
}

.content_inner .header {
    background-color: $color-secondary !important;
}

.message_bubble .message_header .from {
    color: $font-color !important;
}

.border-bottom-primary {
    border-radius: 4px;
}

// Robin
#robin_tab_container {
    bottom: 60px !important;
}

#rbn_pac_container {
    max-width: 300px !important;
}

#robin_tab_div,
#rbn_pac_caption {
    font-family: $font-family !important;
}

#robin_pac_invite {
    bottom: 127px !important;
    left: 52px !important;
    margin-left: unset !important;
    top: unset !important;

    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

#rbn_pac_caption {
    font-size: 15px !important;
}

// Trustedshops
#MyCustomTrustbadge p {
    color: $gray-dark;
}

ul.header_trust {
    border-bottom: 0;
    border-top: 0;
    box-sizing: border-box;
    font-size: 12px;
    height: 40px;
    list-style: none;
    margin: 0;
}

ul.header_trust li {
    display: block;
    float: left;
    line-height: 20px;
}

ul.header_trust li.header_trust_logo {
    display: block;
    float: left;
    padding: 5px 10px;
}

.header_trust {
    display: none;
}

@include media-breakpoint-up(md) {
    .header_trust {
        display: inherit;
    }
}

li.certified .review-aggregate div.star-rating-ts {
    background: url('/img/icons/header/star-grijs.svg') repeat-x;
    background-size: 15px;
    float: left;
    height: 15px;
    width: 75px;
}

li.certified .review-aggregate div.star-rating-ts .color {
    background: $color-secondary-light url('/img/icons/header/star-geel.svg') repeat-x;
    background-size: 15px;
    height: 15px;
}

.ts-rating-light.productreviewsSummary .icon-star-full {
    color: $yellow !important;
}

.ts-rating-light.productreviewsSummary .icon-star-empty {
    color: $ui-line-divider-grey !important;
}

.week_sales .item .ts-stars {
    margin-top: 10px;
}

.merken_header .popular_products .psum .ts-stars {
    display: none;
}

.ts-stars {
    height: 15px;
    margin: 3px 0;
}

.cproduct .ts-stars {
    border-left: 1px solid $gray-dark;
    cursor: pointer;
    display: block;
    float: left;
    margin: 0 0 0 10px !important;
    padding-left: 10px;
}

.related_items .ts-stars,
.accessories_items .ts-stars,
.highlighted_items .ts-stars {
    border: 0;
    display: block;
    float: none;
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding-left: 0;
}


li.ts_usp {
    background: url('/img/icons/check_white.svg') no-repeat left top 5px;
    background-size: 14px;
    padding: 2px 0 2px 20px;
}

#ts-title-stars {
    margin-bottom: 20px;
}

.ts-rating-light.productreviews .ts-wrapper .ts-header-title,
.ts-rating-light.productreviews .ts-wrapper .ts-header-subtitle,
.ts-review-avatar {
    display: none !important;
}

.ts-rating-light.productreviews .ts-wrapper .ts-reviews .ts-reviews-list li .ts-review-info .ts-review-content {
    margin-left: 0 !important;
}

.ts-rating-light.productreviews .ts-wrapper {
    padding: 0 !important;
}

.ts-published-date,
.ts-review-text,
.ts-buyer-info,
.ts-product-name {
    color: $gray-dark !important;
    font-family: $font-family !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.ts-published-date {
    line-height: 16px !important;
}

.ts-product-name {
    font-weight: bold !important;
    margin-top: 5px !important;
}

.ts-review-text {
    margin-top: 0 !important;
}

.h1_footer .ts-rating-light.productreviewsSummary {
    position: relative;
    top: -1px;
}

.ts-rating-light.productreviewsSummary * {
    font-family: $font-family !important;
}

.ts-rating-light.productreviews .ts-wrapper.ts-no-reviews .ts-reviews ul {
    color: $gray-dark !important;
    font-size: 13px !important;
    margin: 0 !important;
    text-align: left !important;
}

#recommendation_box {
    .ts-stars {
        margin: 5px 0 !important;
    }
}

ul.bottommenu li.ts_usp {
    color: $white;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
}


// Filter TODO: Kan wellicht weg, niet te vinden in de code
.filter {
    border: 1px solid $gray-lighter;
    display: block;
    float: left;
    font-size: 12px;
    height: 80px;
    margin: 0 20px 20px 0;
    padding: 0;
    position: relative;
    width: 323px;
}

.filter .reset {
    background: url('#{$images-path}/reset.png') no-repeat right center $white;
    display: block;
    font-size: 11px;
    line-height: 20px;
    padding: 0 20px 0 5px;
    position: absolute;
    right: 15px;
    text-decoration: none;
    top: -5px;
}

.filter .age {
    float: left;
    margin-right: 15px;
    width: auto;
}

.filter .filter_inside {
    padding: 10px;
}

.filter .age_val {
    border: 0;
    float: left;
    margin: 0 5px 5px 0;
    overflow: hidden;
    padding: 0;
    width: 13px;
}

.filter label {
    display: block;
    float: left;
    font-size: 11px;
    width: auto;
}

.filter legend {
    color: $color-primary;
    font-size: 12px;
    font-weight: bold;
    margin: 0 10px;
    padding: 0 5px;
}

// Black friday
div.top_attention_bar .bf_box {
    background: $red;
    display: inline-block;
    line-height: 24px;
    padding: 0 5px;
}

div.top_attention_bar .bf_button {
    border: 1px solid $white;
    display: inline-block;
    font-size: 12px;
    line-height: 22px;
    margin: 0 0 0 40px;
    padding: 0 5px;
    position: relative;
    top: -2px;
}

div.blackfriday_bar {
    background-color: $black;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        border-radius: 5px;
        margin-top: 20px;
    }
}

div.blackfriday_bar a {
    color: $white;
    display: block;
    text-decoration: none;
}

div.blackfriday_bar a span {
    position: relative;
}

div.blackfriday_bar .bf_box {
    background-color: $red-primary;
    border: 1px solid $red-secondary;
    border-radius: 3px;
    display: inline-block;
    line-height: 28px;
    padding: 0 5px;
}

div.blackfriday_bar .bf_button {
    border: 1px solid $white;
    border-radius: 3px;
    display: inline-block;
    line-height: 28px;
    margin-left: 15px;
    padding: 0 5px;

    span {
        font-size: 12px;
        line-height: 22px;
    }
}

div.top_banner:hover .bf_button {
    text-decoration: underline;
    text-underline-offset: 1px;
}

.bf_label {
    left: 0;
    position: absolute;
    top: 0;
    width: 30%;
    z-index: 1;

    img {
        width: 100%;
    }
}

span.kortingbol_s.bf {
    background-color: $blue-bol;
    font-size: 13px;
    font-weight: bold;
}

.week_sales .item .korting.opruiming.blackfriday {
    background-color: $black;
    border-radius: 15px;
    color: $white;
    display: inline-block;
    font-size: 13px;
    line-height: 15px;
    padding: 5px 12px;
    width: auto;
}

.pfoto span.opruiming.blackfriday {
    background-color: $black;
    font-weight: bold;
}

.pfoto span.opruiming.blackfriday::before {
    border: 19px solid $black;
    border-bottom: 19px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.psum .korting.opruiming.blackfriday {
    background-color: $black;
    border-radius: 15px;
    color: $white;
    padding: 5px 12px;
}

.pprijskorting.blackfriday {
    background-color: $black;
    border-radius: 15px;
    color: $white;
    font-weight: 600;
    padding: 0 15px;
}

// Home
.home-block {
    border-right: 1px solid $gray-lighter;
    color: $font-color;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center;
    max-width: 95vw !important;
    min-height: 92px;
    padding: 15px;
    width: 400px;

    @include media-breakpoint-up(lg) {
        background-size: auto 40px !important;
        border-width: 0;
        filter: drop-shadow(0 1px 2px $shadow-black-1);
        flex-grow: 1;
        margin-bottom: 15px;
        min-height: inherit;
        padding: 10px;
        width: 100%;

        &:last-child {
            margin-bottom: 5px;
        }
    }

    @include media-breakpoint-up(xl) {
        background-size: auto 60px !important;
        padding: 15px;
    }

    &:last-child {
        border-right: 0;
    }
}

div.home_txt_box h1 {
    background-color: $gray-lighter;
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;
    margin: 40px 0 20px;
    padding: 0 20px;
    text-transform: uppercase;
}

.home_footer_txt p {
    margin: 0 0 20px;
}

.home_container h5,
.home_container h1 {
    background: none;
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-transform: none;
}

.home_container .week_sales,
.home_container .recent_bekeken {
    margin: 60px 0 0;
}

.home_container .text_offer_box {
    margin: 60px 0 0;
}

.home_container .week_sales h5 {
    color: $font-color;
    margin-bottom: 20px;
}

.home_container .text_offer_box h5 {
    border-bottom: 0;
}

.home_container .text_offer_box .text_box {
    float: left;
    margin: 0 15px 0 0;
    width: 370px;
}

.home_container .text_offer_box .text_box:last-child {
    margin-right: 0;
}

.home_container .text_offer_box .text_box .img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid $gray-lighter;
    display: block;
    height: 200px;
    margin-bottom: 20px;
    width: 100%;
}

.home_container .text_offer_box .text_box a {
    color: $color-primary;
    text-decoration: none;
}

.home_container .text_offer_box .text_box a:hover {
    text-decoration: underline;
}

.home_container .text_offer_box .text_box p {
    margin: 0 !important;
}

.home_container .text_offer_box .text_box h5 a {
    color: $gray-dark;
}

.home_container .text_offer_box .offer_box {
    border: 1px solid $gray-lighter;
    border-radius: 5px;
    box-sizing: border-box;
    float: left;
    margin: 0 0 20px;
    padding: 20px;
    width: 230px;
}

.home_container .text_offer_box .offer_box h5 {
    text-align: center;
}

.home_container .text_offer_box .offer_box #countdown {
    font-size: 11px;
    line-height: 14px;
}

.home_container .text_offer_box .offer_box #countdown span {
    white-space: nowrap;
}

.home_container .text_offer_box .offer_box .stock {
    color: $green;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
}

.home_container .text_offer_box .offer_box .img {
    display: block;
    height: 135px;
    position: relative;
    text-align: center;
    width: 100%;
}

.home_container .text_offer_box .offer_box .img img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 135px;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.home_container .text_offer_box .offer_box .name {
    display: block;
    margin: 10px 0 0;
    text-decoration: none;
}

.home_container .text_offer_box .offer_box .price_box {
    margin: 20px 0 10px;
}

.home_container .text_offer_box .offer_box .price_stripe {
    font-size: 12px;
    text-decoration: line-through;
}

.home_container .text_offer_box .offer_box .price {
    color: $font-color;
    font-size: 16px;
    font-weight: bold;
}

.home_container .text_offer_box .offer_box a.button {
    background: $button-color-primary;
    border-radius: 5px;
    color: $font-color;
    display: inline-block;
    font-size: 13px;
    padding: 10px 20px;
    text-decoration: none;
    transition: all .2s ease-out;
}

.home_container .text_offer_box .offer_box a:hover.button {
    background-color: $color-primary-light;
}

.home_container {
    .main-banner {
        margin-right: 15px;

        @include media-breakpoint-up(md) {
            width: 570px;
        }

        @include media-breakpoint-up(lg) {
            border-radius: 8px;
            filter: drop-shadow(0 1px 2px $shadow-black-1);
            margin-right: 0;
            overflow: hidden;
            width: auto;
        }

        @include media-breakpoint-up(xl) {
            width: 570px;
        }
    }
}

.home_footer_txt {
    background-color: $color-secondary-light;
    margin-bottom: -60px;
    padding-bottom: 40px;
    width: 100%;
}

.home_footer_txt.sinterklaas {
    background: $color-secondary-light url('/img/feestdagen/footer_sinterklaas.svg') no-repeat center bottom -1px;
    background-size: 2898px;
}

.home_footer_txt.kerst {
    background: $color-secondary-light url('/img/feestdagen/footer_kerst.svg') no-repeat center bottom -125px;
    background-size: 2898px;
}

.home_footer_txt .main {
    padding: 50px 20px 30px;
}

.home_footer_txt h2,
.home_footer_txt h1 {
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    margin: 0;
    text-transform: inherit;
}

.home_txt_box {
    margin-bottom: 20px;
}

#home h2 {
    font-size: 14px;
}

ul.home-quickmenu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    list-style: none;
    margin-left: 0;
    width: 100%;

    @include media-breakpoint-down(md) {
        margin-left: 15px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }

    li {
        border-top: 1px solid $ui-line-divider-grey;

        @include media-breakpoint-only(md) {
            &:first-child {
                border-top: 0;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        a {
            padding: 7px;

            &:hover {
                text-decoration: underline !important;
            }
        }

        &:last-child {
            border-bottom: 1px solid $ui-line-divider-grey;
        }
    }

    li,
    a {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        text-decoration: none;
    }
}

// Cart
.cart_bottom_box .btw {
    font-size: 14px;
    line-height: 30px;
}

.cart_fullwidth .ex_verzend_arbeid {
    margin: 40px 0 20px;
}

.cart_fullwidth .ex_verzend_arbeid ul {
    margin: 0;
}

.cart_fullwidth .ex_verzend_arbeid li {
    background: url('/img/icons/info.svg') no-repeat left top 3px;
    background-size: 16px;
    list-style: none;
    margin-left: 0;
    padding-left: 20px;
}

.empty_cart {
    text-align: center;
}

.empty_cart span {
    display: block;
    font-size: 24px;
    margin: 10px 0;
}

.cart_business .delivery_tip {
    display: inline-block;
    float: none;
    margin: 2px 5px 0 0;
    vertical-align: bottom;
}

.cart_business {
    display: inline-block;

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.delivery_moment {
    background: url('/img/icons/transport_green.svg?cache=2020071601') no-repeat left center;
    background-size: 28px;
    color: $green;
    float: right;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 20px;
    padding: 0 20px 0 40px;
}

.cart_delivery_box .delivery_moment {
    background: none;
    color: $green;
    float: none;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 0;
}

.cart_delivery_box .cart_business .delivery_tip {
    margin-top: 0;
}

.delivery_selected {
    background-color: $gray-lighter;
}

#waardebonbox {
    border: 1px solid $gray-lighter;
    display: inline-block;
}

#waardebonbox input {
    border: 0;
    height: 14px;
    padding: 10px;
}

#fullpagebox label {
    float: none;
    width: auto;
}

.cart_left td {
    font-size: 14px;
    line-height: 20px;
}

.cart_left img.product_img {
    margin: 20px 0;
    max-height: 120px;
    max-width: 120px;
}

.cart_left .img-container {
    display: table-cell;
    height: 64px;
    text-align: center;
    vertical-align: middle;
    width: 64px;

    @include media-breakpoint-up(sm) {
        height: 96px;
        width: 96px;
    }

    @include media-breakpoint-up(md) {
        height: 120px;
        width: 120px;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }
}

.cart_left a {
    font-size: 14px;
    text-decoration: none;
}

.cart_left a:hover {
    color: $color-primary;
    text-decoration: underline;
}

.cart_left a.product_del {
    &::before {
        background: url('#{$images-path}icons/trashcan.svg') no-repeat right center;
        background-size: cover;
        content: '';
        display: inline-block;
        height: 15px;
        margin-right: 5px;
        vertical-align: top;
        width: 15px;
    }

    &:hover {
        text-decoration: underline;
    }
}

.cart_right {
    float: left;
    margin-left: 40px;
    width: 330px;
}

.cart_right table tr td {
    line-height: 20px;
    padding: 4px 0;
}

.cart_right .cart_right_box {
    border: 1px solid $gray-lighter;
    margin-bottom: 10px;
}

.cart_right .cart_right_box .padding_10 {
    padding: 10px 15px;
}

.cart_right .cart_right_box .border_bottom {
    border-bottom: 1px solid $gray-lighter;
}

.cart_right .delivery_box {
    background-color: $gray-green;
    margin: 20px 0 10px;
    padding: 10px 0;
}

.cart_right .delivery_box p {
    margin: 0 !important;
    padding: 10px 15px 0;
}

.cart_right .usp_box {
    background-color: $gray-lighter;
    margin-bottom: 10px;
    padding: 10px 0;
}

.cart_right ul.usp_pdp {
    background: transparent;
    padding: 15px;
}

.cart_right table:last-child {
    border-bottom: 0 !important;
}

.cart_bottom_box {
    background-color: $color-secondary-light;
    padding: 20px;
}

#cart_form label.error {
    background-color: $white-orange;
    border: 1px solid $orange;
    display: block;
    line-height: 20px;
    margin-top: 10px;
    padding: 10px;
    text-align: left;
    z-index: 1;
}

#cart_form label.error#levermethode-error {
    bottom: -50px;
    font-size: 14px;
    left: -144px;
    top: inherit;
    width: 200px;
}

select.afmonteren {
    position: relative;
    z-index: 2;
}

.tegoedbonbox,
.actiecodebox {
    display: none;
}

#tegoedbon {
    background: no-repeat right 5px center;
    background-size: 16px;
    width: 200px;
}

.table-customizations-cart {
    table-layout: auto;

    tr td:first-child {
        padding-left: 0;
    }

    td {
        line-height: inherit;
        padding-left: 20px;
    }
}

.pdp-legend-container {
    display: flex;
    gap: 10px;
}

.pdp-legend-button {
    text-decoration: none;
    cursor: pointer;
    width: auto;
    background-color: $white;
    border: 1px solid $font-color;
    border-radius: 74px;
    box-shadow: 0 1px 2px $shadow-black-1 !important;
    color: $font-color;
    font-size: 14px;
    padding: 10px 20px;

    &:hover {
        border-color: $color-primary-light;
        color: $color-primary-light;
    }
}

.articles-wrapper {
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

.email-exist {
    li {
        label {
            margin-bottom: 0;
        }
    }
}

.account-submit {
    width: 100%;
    max-width: 230px;
}

#column_left {
    width: 100%;
    padding: 0 15px;
    @include media-breakpoint-up(lg) {
        width: 64%;
    }
}

#column_right {
    width: 100%;
    padding: 0 15px;
    @include media-breakpoint-up(lg) {
        width: 34%;
    }
}

[data-cookiefirst-action="open-preferences"] {
    @include media-breakpoint-down(md) {
        margin-bottom: 70px;
    }
}

.swiper-wrapper {
    height: auto;
}

.fietszeker-pdp-container {
    border: 1px solid $ui-line-divider-grey;
    border-radius: 3px;
    margin-top: .75rem;
    padding: .25rem .75rem;
    font-weight: 500;
    font-size: 12px;
}

.fietszeker-logo {
    text-decoration: none;
    :hover {
        text-decoration: none!important;
    }
}

.insurance-submit, .insurance-confirm {
    align-self: flex-start;
    background-color: $color-secondary;
    border-radius: 999px;
    border: none;
    color: $white;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.25);
    padding: 10px;
    &:hover {
        background-color: $color-secondary-hover;
    }
}

.insurance-price-container {
    display: inline-flex;
    margin-bottom: .5rem;
    gap: .25rem;
}

.insurance-price {
    font-size: large;
}

.insurance-message-error {
    margin-bottom: .5rem;
    color: $red-primary;
}
.insurance-fields-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.insurance-fields-row {
    display: flex;
    gap: 10px;
    width: 100%;
    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
}

.insurance-fields-row label {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.insurance-fields-row input,
.insurance-fields-row select {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
}

.insurance-application-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    min-width: 320px;
    label {
        cursor: pointer;
        display: inline-flex;
        input {
            cursor: pointer;
            margin-right: .5em;
        }
    }
}

.fietszeker-cart-text-container {
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        span {
            flex-grow: 1;
        }
    }
}

.insurance-button-checked {

}
